import React from 'react';
import { FC } from 'react';
import { Link } from './Documentation';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const DocumentationSection: FC<DocumentationSectionProps> = ({
  title,
  docs,
}: DocumentationSectionProps) => {
  const { i18n } = useTranslation();

  const handleClick = (link: Link): void => {
    let langFolder: string;
    let fileName: string;

    switch (i18n.language) {
      case 'fr':
        langFolder = 'fr';
        fileName = link.path.fr;
        break;
      case 'en':
      case 'bg':
      case 'gr':
      case 'de':
      case 'es':
      case 'ru':
      case 'ptBr':
      default:
        langFolder = 'en';
        fileName = link.path.en;
        break;
    }

    if (!fileName) {
      fileName = link.path.en;
      langFolder = 'en';
    }

    const filePath = `/docs/${langFolder}/${fileName}`;
    window.open(filePath, '_blank');
  };

  return (
    <section>
      <h2 className="text-xl text-left font-semibold text-black mb-4">{title}</h2>
      <ul className="space-y-2 md:space-y-4 m-0 p-0">
        {docs.map((link: Link, index: number) => (
          <li
            key={index}
            className="flex flex-wrap items-center justify-start space-x-4 border-b border-gray-200 pb-2"
          >
            <button
              className="px-4 py-2 text-white bg-cyan-500 hover:bg-cyan-600 rounded-lg shadow-md transition-transform transform hover:scale-105"
              onClick={(): void => handleClick(link)}
            >
              {t('documentation_view_pdf')}
            </button>
            <div
              className="flex-1 text-left text-lg font-medium text-cyan-700 hover:text-cyan-500 break-words cursor-pointer"
              onClick={(): void => handleClick(link)}
            >
              {link.title}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

interface DocumentationSectionProps {
  title: string;
  docs: Link[];
}

export default DocumentationSection;
