import React, { ChangeEvent, FC, useState } from 'react';
import {
  CreateTemplatedDeviceDto,
  Device,
  DeviceCreateUpdateBody,
  DeviceIconDto,
} from '@thingslog/repositories';
import { DeviceModel } from '@thingslog/repositories';
import { useTranslation } from 'react-i18next';
import { Tab } from '@mui/material';
import { CreateOrUpdateDeviceProps } from './models/CreateOrUpdateDeviceProps';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DeviceMutationForm from './FormDeviceMutation';
import FormTemplateDeviceCreate from './FormTemplateDeviceCreate';

type Mode = 'manual' | 'template';

const InventoryAddDeviceModal: FC<InventoryModalProps> = ({
  iconsDropdownOptions,
  createOrUpdateDeviceData,
  onCreateDeviceManual,
  onUpdateDeviceManual,
  onCreateTemplate,
  devices,
  deviceModelsDropdownOptions,
  canEditDeviceModel,
  deviceNumberPrefix,
}: InventoryModalProps) => {
  const [mode, setMode] = useState<Mode>('manual');
  const { t } = useTranslation();

  if (createOrUpdateDeviceData.type === 'UPDATE') {
    return (
      <DeviceMutationForm
        createOrUpdateDeviceData={createOrUpdateDeviceData}
        iconsDropdownOptions={iconsDropdownOptions}
        deviceModelsDropdownOptions={deviceModelsDropdownOptions}
        onUpdateDeviceManual={onUpdateDeviceManual}
        onCreateDeviceManual={onCreateDeviceManual}
        canEditDeviceModel={canEditDeviceModel}
      />
    );
  }

  return (
    <TabContext value={mode}>
      <TabList onChange={(e: ChangeEvent<{}>, value: Mode): void => setMode(value)}>
        <Tab label={t('device_inventory_manual')} value="manual" />
        <Tab label={t('device_inventory_template')} value="template" />
      </TabList>
      <TabPanel value="manual" className="p-0">
        <DeviceMutationForm
          createOrUpdateDeviceData={createOrUpdateDeviceData}
          iconsDropdownOptions={iconsDropdownOptions}
          deviceModelsDropdownOptions={deviceModelsDropdownOptions}
          onUpdateDeviceManual={onUpdateDeviceManual}
          onCreateDeviceManual={onCreateDeviceManual}
          deviceNumberPrefix={deviceNumberPrefix}
        />
      </TabPanel>
      <TabPanel value="template" className="p-0">
        <FormTemplateDeviceCreate
          onSubmit={(payload: CreateTemplatedDeviceDto): void => {
            onCreateTemplate && onCreateTemplate(payload);
          }}
          devices={devices}
        />
      </TabPanel>
    </TabContext>
  );
};

export interface InventoryModalProps {
  createOrUpdateDeviceData: CreateOrUpdateDeviceProps;
  onUpdateDeviceManual?: (device: string, body: DeviceCreateUpdateBody) => void;
  onCreateDeviceManual?: (device: DeviceCreateUpdateBody) => void;
  onCreateTemplate?: (device: CreateTemplatedDeviceDto) => void;
  iconsDropdownOptions: DeviceIconDto[];
  deviceModelsDropdownOptions: DeviceModel[];
  canEditDeviceModel: boolean;
  deviceNumberPrefix: string | undefined;
  devices: Device[];
}

export default InventoryAddDeviceModal;
