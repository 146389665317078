import React, { FC } from 'react';
import Header from '../../components/header';
import { Link } from 'react-router-dom';
import DocumentationSection from './DocumentationSection';
import { useTranslation } from 'react-i18next';

const Documentation: FC<DocumentationProps> = ({}: DocumentationProps) => {
  const { t } = useTranslation();
  const platformDocs: Link[] = [
    {
      title: t('documentation_sign_in_main_menu'),
      path: {
        en: 'sign-in-and-main-menu.pdf',
        fr: '[01]Connexion_et_Menu_Principal.pdf',
      },
    },
    {
      title: t('documentation_account_management'),
      path: {
        en: 'thingslog-account-management.pdf',
        fr: '[02]Gestion_des_Comptes.pdf',
      },
    },
    {
      title: t('documentation_user_management'),
      path: {
        en: 'thingslog-user-management.pdf',
        fr: '[03]Gestion_des_Utilisateurs.pdf',
      },
    },
    {
      title: t('documentation_data_loggers_configuration'),
      path: {
        en: 'thingslog-data-loggers-configuration.pdf',
        fr: '[04]Configuration_des _Data_Logger.pdf',
      },
    },
    {
      title: t('documentation_alarms_value_range_events'),
      path: {
        en: 'configuring-and-reviewing-things-log-alarms-and-value-range-events.pdf',
        fr: '[05]Gestion_des_Alarmes_et_des_Evenements.pdf',
      },
    },
    {
      title: t('documentation_modbus_slave_devices'),
      path: {
        en: 'port-sensor-parameter-builder-for-modbus-slave-devices.pdf',
        fr: '[06]Gestion_des_dispositifs_esclaves_Modbus.pdf',
      },
    },
    {
      title: t('documentation_tasmota_devices'),
      path: {
        en: 'setting-up-tasmota-devices-to-work-with-thingslog-platform.pdf',
        fr: '[07]Configuration_des_dispositifs_Tasmota.pdf',
      },
    },
    {
      title: t('documentation_modbus_setup'),
      path: {
        en: 'setting-up-modbus-slave-devices-to-work-with-thingslog-controllers.pdf',
        fr: '[08]Configuration_des_dispositifs_esclaves_Modbus.pdf',
      },
    },
    {
      title: t('documentation_licenses'),
      path: {
        en: 'licenses.pdf',
        fr: '[09]Gestion_des_Licences.pdf',
      },
    },
    {
      title: t('documentation_old_user_manual'),
      path: {
        en: 'tl-en.pdf',
        fr: '[10]Manuel_Utilisateur_Plateforme_Thingslog.pdf',
      },
    },
  ];

  const dataLoggerDocs: Link[] = [
    {
      title: t('documentation_data_logger_manual'),
      path: {
        en: 'LPMDL-110x-EN_v1.10.2024.pdf',
        fr: '[11]LPMDL-110x-FR_Manuel_Utilisateur.pdf',
      },
    },
    {
      title: t('documentation_data_logger_command_guide'),
      path: {
        en: 'LPMDL_110x_command_guide-EN-4.9.6.pdf',
        fr: '[12]LPMDL-110x-FR_Guide_Commandes_En_Lignes.pdf',
      },
    },
  ];

  const communicationProtocolDocs: Link[] = [
    {
      title: t('documentation_lora_protocol'),
      path: {
        en: 'LPMDL-110X-LoRa-open-data-protocol.pdf',
        fr: '[13]LPMDL-110x-FR_Protocole_LoRa.pdf',
      },
    },
    {
      title: t('documentation_mqtt_protocol'),
      path: {
        en: 'LPMDL-110x-MQTT-open-data-protocol.pdf',
        fr: '[14]LPMDL-110x-FR_Protocole_MQTT.pdf',
      },
    },
  ];

  return (
    <Header>
      <div className="flex justify-center items-center mb-6">
        <h1 className="text-2xl font-bold text-black text-center">{t('documentation_title')}</h1>
      </div>
      <div className="w-full max-w-fit mx-auto space-y-8">
        <DocumentationSection title={t('documentation_platform')} docs={platformDocs} />
        <DocumentationSection title={t('documentation_data_logger')} docs={dataLoggerDocs} />
        <DocumentationSection
          title={t('documentation_communication_protocol')}
          docs={communicationProtocolDocs}
        />
      </div>
    </Header>
  );
};

export interface DocumentationProps {}

export interface DocumentationProps {}

export interface Link {
  title: string;
  path: Record<Language, string>;
}

export type Language = 'en' | 'fr';

export default Documentation;
