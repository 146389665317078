import Axios from '../Axios/Axios';
import {
  createAlarmsExportQueryClient,
  createDevicesQueryClient,
  createEventValueRangeQueryClient,
  createInitialConfigQueryClient,
  createDeviceConfigQueryClient,
  createDeviceFlowQueryClient,
  createDeviceCountersQueryClient,
  createMultipleConsumptionExportQueryClient,
  createFeatureFlagsQueryClient,
  createMultipleReadingsExportQueryClient,
  createTransmissionsQueryClient,
  createDevicesSignalQueryClient,
  createDevicesBatteryQueryClient,
  createDevicesIconsQueryClient,
  createSettingsQueryClient,
  createDeviceGroupsQueryClient,
  createTimeSeriesPredictionQueryClient,
  createMapSettingsQueryClient,
  createDeviceLocationsQueryClient,
  createClientCountQueryClient,
  createCompanyInfoQueryClient,
  createUtilityRateQueryClient,
  createCompanyQueryClient,
  createUserQueryClient,
  createIconsQueryClient,
  createSensorAllowedUnitsQueryClient,
  createLicenseQueryClient,
  createLinkDeviceQueryClient,
  createCustomerBillingReportQueryClient,
  createTimezonesDeviceQueryClient,
  createResetPasswordQueryClient,
  createSensorDefaultConfigQueryClient,
  createFormulaValidatorQueryClient,
  createCountriesQueryClient,
  createVersionInfoQueryClient,
  createDeviceModelsQueryClient,
  createDeviceNodeLinksQueryClient,
} from '@thingslog/queries';

export const sensorDefaultConfigQueryClient = createSensorDefaultConfigQueryClient(Axios);
export const eventValueRangeQueryClient = createEventValueRangeQueryClient(Axios);
export const devicesQueryClient = createDevicesQueryClient(Axios);
export const companyQueryClient = createCompanyQueryClient(Axios);
export const utilityRateQueryClient = createUtilityRateQueryClient(Axios);
export const multipleReadingsExportQueryClient = createMultipleReadingsExportQueryClient(Axios);
export const multipleConsumptionExportQueryClient =
  createMultipleConsumptionExportQueryClient(Axios);
export const initialConfigQueryClient = createInitialConfigQueryClient(Axios);
export const deviceConfigQueryClient = createDeviceConfigQueryClient(Axios);
export const deviceFlowQueryClient = createDeviceFlowQueryClient(Axios);
export const deviceCountersQueryClient = createDeviceCountersQueryClient(Axios);
export const alarmsQueryClient = createAlarmsExportQueryClient(Axios);
export const featureFlagsQueryClient = createFeatureFlagsQueryClient(Axios);
export const transmissionsQueryClient = createTransmissionsQueryClient(Axios);
export const devicesSignalsQueryClient = createDevicesSignalQueryClient(Axios);
export const devicesBatteryQueryClient = createDevicesBatteryQueryClient(Axios);
export const devicesIconsQueryClient = createDevicesIconsQueryClient(Axios);
export const settingsQueryClient = createSettingsQueryClient(Axios);
export const deviceGroupsQueryClient = createDeviceGroupsQueryClient(Axios);
export const timeSeriesPredictionQueryClient = createTimeSeriesPredictionQueryClient(Axios);
export const mapSettingsQueryClient = createMapSettingsQueryClient(Axios);
export const deviceLocationQueryClient = createDeviceLocationsQueryClient(Axios);
export const clientCountQueryClient = createClientCountQueryClient(Axios);
export const userQueryClient = createUserQueryClient(Axios);
export const companyInfoQueryClient = createCompanyInfoQueryClient(Axios);
export const iconsQueryClient = createIconsQueryClient(Axios);
export const sensorAllowedUnitsQueryClient = createSensorAllowedUnitsQueryClient(Axios);
export const licenseQueryClient = createLicenseQueryClient(Axios);
export const linkDevicesQueryClient = createLinkDeviceQueryClient(Axios);
export const customerBillingReportQueryClient = createCustomerBillingReportQueryClient(Axios);
export const timezonesQueryClient = createTimezonesDeviceQueryClient(Axios);
export const resetPasswordQueryClient = createResetPasswordQueryClient(Axios);
export const formulaValidatorQueryClient = createFormulaValidatorQueryClient(Axios);
export const countriesQueryClient = createCountriesQueryClient(Axios);
export const versionInfoQueryClient = createVersionInfoQueryClient(Axios);
export const deviceModelsQueryClient = createDeviceModelsQueryClient(Axios);
export const deviceNodeLinksQueryClient = createDeviceNodeLinksQueryClient(Axios);
