import React, { FC, ReactNode, useEffect, useState } from 'react';
import {
  useForm,
  Controller,
  SubmitHandler,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
} from 'react-hook-form';
import { CreateTemplatedDeviceDto, Device, DeviceModel } from '@thingslog/repositories';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  FormControl,
  TextField,
} from '@mui/material';

interface ReferenceSolutionOption {
  label: string;
  value: string;
}

interface FormTemplateDeviceCreateProps {
  onSubmit: (data: CreateTemplatedDeviceDto) => void;
  devices: Device[];
}

const FormTemplateDeviceCreate: FC<FormTemplateDeviceCreateProps> = ({
  onSubmit,
  devices,
}: FormTemplateDeviceCreateProps) => {
  const { t } = useTranslation();
  const [selectedReferenceDevice, setSelectedReferenceDevice] = useState<Device | null>(null);

  // Generate reference device options for dropdown
  const referenceSolutionOptions: ReferenceSolutionOption[] = React.useMemo(() => {
    return devices.map((d: Device) => ({
      label: `${d.name} (${d.number})`,
      value: d.number,
    }));
  }, [devices]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    trigger,
  } = useForm<CreateTemplatedDeviceDto>({
    defaultValues: {
      deviceName: '',
      deviceNumber: '',
      referenceDeviceNumber: '',
      hwVersion: null,
      swVersion: null,
      nomenclature: null,
    },
    mode: 'onChange',
  });

  const watchReferenceNumber = watch('referenceDeviceNumber');

  // Update selected reference device when reference number changes
  useEffect(() => {
    const device = devices.find((d: Device) => d.number === watchReferenceNumber) || null;
    setSelectedReferenceDevice(device);

    // Re-validate nomenclature field when reference device changes
    trigger('nomenclature');
  }, [watchReferenceNumber, devices, trigger]);

  // Function to determine if a device model requires nomenclature
  const doesDeviceRequireNomenclature = (model: DeviceModel | undefined): boolean => {
    if (!model) return false;

    switch (model) {
      case DeviceModel.LPMDL_1101:
      case DeviceModel.LPMDL_1102:
      case DeviceModel.LPMDL_1103:
      case DeviceModel.LPMDL_1103_OPENLORA:
      case DeviceModel.LPMDL_1104:
      case DeviceModel.LPMDL_1105:
      case DeviceModel.LPMDL_1106:
      case DeviceModel.TLCTL_1104:
      case DeviceModel.TLCTL_1105:
      case DeviceModel.TLCTL_1106:
      case DeviceModel.TLCTL_21A4S:
        return true;
      default:
        return false;
    }
  };

  // Check if nomenclature is required based on reference device model
  const isNomenclatureRequired = (): boolean => {
    return selectedReferenceDevice
      ? doesDeviceRequireNomenclature(selectedReferenceDevice?.model)
      : false;
  };

  const handleFormSubmit: SubmitHandler<CreateTemplatedDeviceDto> = (
    data: CreateTemplatedDeviceDto
  ) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col mt-5 gap-5">
      <FormControl>
        <Controller
          name="deviceNumber"
          control={control}
          rules={{
            required: t('device_inventory_empty_device_error') as string,
            pattern: {
              value: /^[a-zA-Z0-9]{8}$/,
              message: t('device_inventory_device_number_helper'),
            },
          }}
          render={({
            field,
          }: {
            field: ControllerRenderProps<CreateTemplatedDeviceDto, 'deviceNumber'>;
            fieldState: ControllerFieldState;
            formState: UseFormStateReturn<CreateTemplatedDeviceDto>;
          }): React.ReactElement => (
            <TextField
              {...field}
              size="small"
              label={t('device_inventory_device_number')}
              error={!!errors.deviceNumber}
              helperText={errors.deviceNumber?.message}
              required
            />
          )}
        />
      </FormControl>

      <Controller
        name="deviceName"
        control={control}
        rules={{ required: t('device_inventory_empty_device_name_error') as string }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<CreateTemplatedDeviceDto, 'deviceName'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<CreateTemplatedDeviceDto>;
        }): React.ReactElement => (
          <TextField
            {...field}
            size="small"
            label={t('device_inventory_device_name')}
            error={!!errors.deviceName}
            helperText={errors.deviceName?.message}
            required
          />
        )}
      />

      <Controller
        name="referenceDeviceNumber"
        control={control}
        rules={{ required: t('device_inventory_empty_reference_error') as string }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<CreateTemplatedDeviceDto, 'referenceDeviceNumber'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<CreateTemplatedDeviceDto>;
        }): React.ReactElement => (
          <Autocomplete
            options={referenceSolutionOptions}
            value={
              referenceSolutionOptions.find(
                (option: ReferenceSolutionOption) => option.value === field.value
              ) || null
            }
            onChange={(_: React.SyntheticEvent, option: ReferenceSolutionOption | null): void => {
              field.onChange(option ? option.value : '');
            }}
            renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
              <TextField
                {...params}
                size="small"
                label={t('device_inventory_reference_device_number')}
                error={!!errors.referenceDeviceNumber}
                helperText={errors.referenceDeviceNumber?.message}
                required
              />
            )}
          />
        )}
      />
      <Controller
        name="hwVersion"
        control={control}
        rules={{
          pattern: {
            value: /^([1-9][0-9]*)(?:\.([0-9]{1,2})){2}$/,
            message: t('device_inventory_hardware_version_helper') as string,
          },
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<CreateTemplatedDeviceDto, 'hwVersion'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<CreateTemplatedDeviceDto>;
        }): React.ReactElement => (
          <TextField
            {...field}
            size="small"
            label={t('device_inventory_hardware_version')}
            error={!!errors.hwVersion}
            helperText={errors.hwVersion?.message}
            value={field.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
              field.onChange(e.target.value || null)
            }
          />
        )}
      />

      <Controller
        name="swVersion"
        control={control}
        rules={{
          pattern: {
            value: /^(\d{1,2}\.\d{1,2}\.\d{1,2})(-.+|_.+|)$/,
            message: t('device_inventory_software_version_helper') as string,
          },
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<CreateTemplatedDeviceDto, 'swVersion'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<CreateTemplatedDeviceDto>;
        }): React.ReactElement => (
          <TextField
            {...field}
            size="small"
            label={t('device_inventory_software_version')}
            error={!!errors.swVersion}
            helperText={errors.swVersion?.message}
            value={field.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
              field.onChange(e.target.value || null)
            }
          />
        )}
      />

      <Controller
        name="nomenclature"
        control={control}
        rules={{
          required: isNomenclatureRequired()
            ? (t('device_inventory_empty_nomenclature_error') as string)
            : false,
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<CreateTemplatedDeviceDto, 'nomenclature'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<CreateTemplatedDeviceDto>;
        }): React.ReactElement => (
          <TextField
            {...field}
            size="small"
            label={t('nomenclature')}
            error={!!errors.nomenclature}
            helperText={errors.nomenclature?.message}
            required={isNomenclatureRequired()}
            value={field.value || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
              field.onChange(e.target.value || null)
            }
          />
        )}
      />

      {selectedReferenceDevice && isNomenclatureRequired() && (
        <Alert severity="info">
          {t('device_inventory_nomenclature_required_for_model', {
            model: selectedReferenceDevice.model,
          })}
        </Alert>
      )}

      <Button type="submit" disabled={!isValid} variant="contained">
        {t('device_inventory_create_device_button')}
      </Button>
    </form>
  );
};

export default FormTemplateDeviceCreate;
