const i18n_fr = {
  close_btn: 'Fermer',
  search_placeholder: 'Rechercher',

  label_inactive: 'Inactif',
  label_invalid: 'Invalide',

  active_devices: ' {{devices_len}} Dispositifs actifs',
  change_language: 'Changer de langue',
  language_changed_message: 'Langue modifiée',

  error_select_device_message: "Veuillez d'abord sélectionner un dispositif",
  select_device: 'Sélectionnez le dispositif',
  select_company_menu_message: 'Sélectionnez une entreprise pour voir la liste des dispositifs',

  second: 'seconde',
  seconds: 'secondes',
  second_one: 'seconde',
  second_other: 'secondes',
  minute: 'minute',
  minutes: 'minutes',
  minute_one: 'minute',
  minute_other: 'minutes',
  hour: 'heure',
  hours: 'heures',
  hour_one: 'heure',
  hour_other: 'heures',
  day: 'jour',
  days: 'jours',
  day_one: 'jour',
  day_other: 'jours',
  start_date: 'Date de début',
  end_date: 'Date de fin',
  from_date: 'Depuis',
  to_date: "Jusqu'à",
  avg_from_date: 'Moyenne depuis la date',
  avg_to_date: "Moyenne jusqu'à la date",
  period: 'Période',
  description: 'Description',
  date: 'Date',
  number: 'Numéro de série',
  sensor: 'ID Capteur',
  apply: 'Appliquer',
  update: 'Mettre à jour',
  create: 'Créer',
  delete: 'Supprimer',
  export: 'Exporter',
  rssi: 'RSSI',
  ber: 'BER',
  reading: 'Lecture',
  model: 'Modèle',
  email_address: 'Adresse e-mail',
  password: 'Mot de passe',
  confirm_password: 'Confirmer le mot de passe',
  sign_in: 'Se connecter',
  graph: 'Graphique',
  no_data: 'Pas de données',
  every: 'Chaque',
  for_company: 'Pour la compagnie',
  flow: 'Débit',
  no_data_found: 'Données non trouvées.',
  percent: 'Pourcentage',
  device_number_devicenum: 'Numéro de série du dispositif: {{device_number}}',
  device_number: 'Numéro de série du dispositif',
  device_name: 'Nom de l’appareil',
  successfully_saved: 'Enregistré avec succès',
  error_occured: "Une erreur s'est produite",
  none: 'Aucun',
  off: 'Éteint',
  no_unit: 'Sans unité de mesure',
  hardware_version: 'Version du matériel',
  software_version: 'Version du logiciel',
  record_period: "Période d'enregistrement",
  transmission_period: 'Période de transmission',

  createdOn: 'Créé le',
  createdBy: 'Créé par',

  show_average: 'Afficher la moyenne',
  show_readings: 'Afficher les lectures',

  yes: 'Oui',
  no: 'Non',
  capital_yes: 'OUI',
  capital_no: 'NON',

  sensor: 'Capteur',

  all_sensors: 'Tous les capteurs',

  select_all: 'Tout sélectionner',
  deselect_all: 'Tout désélectionner',

  function_last: 'Dernière',
  function_first: 'Première',
  function_avg: 'Moyenne',
  function_min: 'Min',
  function_max: 'Max',
  function_median: 'Médiane',
  function_count: 'Compte',
  function_last_flow: 'Dernier débit',
  function_first_flow: 'Premier débit',
  function_avg_flow: 'Débit moyen',
  function_max_flow: 'Débit max',
  function_min_flow: 'Débit min',
  function_median_flow: 'Débit médian',

  page_title_header_number_with_name_absent: '{{title}} - {{device_number}}',
  page_title_header_number_with_name_present: '{{title}} - {{device_name}} ({{device_number}})',

  error_forbidden_operation_not_allowed: 'Non autorisé',
  not_set: 'Non défini',

  // Sensor Types
  generic_analog_sensor: 'Capteur analogique générique',
  generic_virtual_sensor: 'Capteur virtuel générique',
  generic_digital_sensor: 'Capteur numérique générique',
  modbus_generic_analog_sensor: 'Capteur analogique générique Modbus',
  modbus_generic_digital_sensor: 'Capteur numérique générique Modbus',
  pressure_sensor: 'Capteur de pression',
  temperature_sensor: 'Capteur de température',
  humidity_sensor: "Capteur d'humidité",
  level_sensor: 'Capteur de niveau',
  weight_meter: 'Mesure du poids',
  weight_sensor: 'Capteur de poids',
  load_cells: 'Cellules de charge',
  wind_direction_sensor: 'Capteur de direction du vent',
  barometric_pressure_sensor: 'Capteur de pression barométrique',
  soil_moisture_sensor: "Capteur d'humidité du sol",
  dew_point_sensor: 'Capteur de point de rosée',
  soil_temperature_sensor: 'Capteur de température du sol',
  soil_potential_sensor: 'Capteur de potentiel du sol',
  particulate_matter_sensor: 'Capteur de matière particulaire',
  leaf_wetness_sensor: "Capteur d'humidité des feuilles",
  water_meter: "Compteur d'eau",
  power_meter: 'Compteur de puissance',
  gas_flow_meter: 'Compteur de débit de gaz',
  rain_sensor: 'Capteur de pluie',
  wind_speed_sensor: 'Capteur de vitesse du vent',
  heat_meter: 'Compteur de chaleur',
  geiger_mueller_counter: 'Compteur Geiger Muller',
  on_off_sensor: 'Capteur On/Off',
  on_off_input_sensor: "Capteur d'entrée On/Off",
  on_off_output_sensor: 'Capteur de sortie On/Off',
  on_off_auto_output_sensor: 'Capteur de sortie On/Off/Auto',
  tasmota_relay_sensor: 'Capteur de relais Tasmota',
  modbus_generic_on_off_input_sensor: "Capteur d'entrée On/Off Modbus générique",
  modbus_generic_on_off_output_sensor: 'Capteur de sortie On/Off Modbus générique',
  co_sensor: 'Capteur de CO',
  no2_sensor: 'Capteur de NO2',
  so2_sensor: 'Capteur de SO2',
  tasmota_generic_digital_sensor: 'Capteur numérique générique Tasmota',
  tasmota_alarm_input_sensor: "Capteur d'entrée d'alarme Tasmota",
  tasmota_generic_analog_sensor: 'Capteur analogique générique Tasmota',

  // Port Types
  digital_input_port: "Port d'entrée numérique",
  pulse_input_port: "Port d'entrée à impulsions",
  pulse_s0_input_port: "Port d'entrée à impulsions S0",
  analog_input_port: "Port d'entrée analogique",
  '4_20_mA_input_port': "Port d'entrée 4-20mA",
  '0_5_V_input_port': "Port d'entrée 0-5V",
  on_off_input_port: "Port d'entrée On/Off",
  on_off_output_port: 'Port de sortie On/Off',
  generic_modbus_on_off_input_port: "Port d'entrée On/Off Modbus générique",
  generic_modbus_on_off_output_port: 'Port de sortie On/Off Modbus générique',

  /*
   * |===============|
   * | Period Picker |
   * |===============|
   */
  period_date_range: 'Plage de dates',
  period_custom: 'Personnalisé',
  period_today: "Aujourd'hui",
  period_week: 'Semaine',
  period_month: 'Mois',
  period_yesterday: 'Hier',
  period_last_week: 'La semaine dernière',
  period_last_month: 'Le mois dernier',
  period_last_1_hours: 'Dernière heure',
  period_last_2_hours: 'Dernières 2 heures',
  period_last_4_hours: 'Dernières 4 heures',
  period_last_24_hours: 'Dernières 24 heures',
  period_last_48_hours: 'Dernières 48 heures',

  /*
   * |=====================|
   * | Hook Error Messages |
   * |=====================|
   */
  error_cannot_fetch_device: 'Impossible de récupérer le dispositif.',
  error_cannot_fetch_device_config: 'Impossible de récupérer la configuration du dispositif.',
  error_cannot_fetch_ports_config: 'Impossible de récupérer la configuration des ports.',
  error_cannot_fetch_flow_stats: 'Impossible de récupérer les statistiques de débit.',
  error_cannot_fetch_time_stats: 'Impossible de récupérer les statistiques de temps.',
  error_cannot_fetch_general_stats: 'Impossible de récupérer les statistiques générales.',
  error_cannot_fetch_qflow_stats: 'Impossible de récupérer les statistiques de qflow.',

  signup_company_name: "Nom de l'entreprise",

  sign_up_form_first_name: 'Prénom',
  sign_up_form_last_name: 'Nom',
  sign_up_form_email: 'Adresse e-mail',
  sign_up_form_password: 'Mot de passe',
  sign_up_form_confirm_password: 'Confirmer le mot de passe',
  sign_up_form_company_name: "Nom de l'entreprise",

  button_save: 'Enregistrer',
  button_apply: 'Appliquer',
  button_update: 'Mettre à jour',
  button_create: 'Créer',
  button_delete: 'Supprimer',
  button_continue: 'Continuer',
  button_back: 'Retour',

  capital_add_btn: 'AJOUTER',
  capital_create_btn: 'CRÉER',
  capital_update_btn: 'METTRE À JOUR',
  capital_remove_btn: 'SUPPRIMER',
  capital_cancel_btn: 'ANNULER',

  role_roles: 'Rôles',
  role_user: 'Utilisateur',
  role_public: 'Public',
  role_admin: 'Administrateur',
  role_super_admin: 'Super-Administrateur',
  role_restricted: 'Restreint',

  device_counters_cumulative_graph_graph: "Graphique, numéro de l'appareil : {{device_number}}",
  device_counters_cumulative_graph_must_have_analog_sensor:
    'Un capteur analogique doit être activé.',

  sensor_statistics_min: 'Min : {{min}}',
  sensor_statistics_max: 'Max : {{max}}',
  sensor_statistics_avg: 'Moyenne : {{avrg}}',
  sensor_statistics_total: 'Total : {{total}}',

  chart_statistics_min: 'Min :',
  chart_statistics_max: 'Max :',
  chart_statistics_avg: 'Moyenne :',
  chart_statistics_total: 'Total :',

  menu_main: 'Principal',
  menu_device: 'Dispositif',

  submenu_devices: 'Dispositifs',
  submenu_account: 'Compte',
  submenu_device_reports: 'Rapports de dispositifs',
  submenu_settings: 'Paramètres',

  link_selected_device: 'Dispositif sélectionné',
  link_device_nodes: 'Nœuds de dispositifs',
  link_devices: 'Liste des dispositifs',
  link_accounts: 'Comptes',
  link_utility_rate: 'Tarif',
  link_link_device: 'Lier le dispositif',
  link_create_user: 'Créer un utilisateur',
  link_icons: 'Icônes',
  link_licenses: 'Licences',
  link_accounts_manager: 'Gestion des comptes',
  link_transmissions: 'Transmissions',
  link_map: 'Carte',
  link_inventory: 'Inventaire',
  link_devices_manager: 'Gestionnaire de dispositifs',
  link_battery: 'Batterie',
  device_groups: 'Groupes de dispositifs',
  link_signal: 'Signal',
  link_alarms: 'Alarmes',
  link_value_ranges: 'Événements de plage de valeur',
  link_device_account_lookup: 'Recherche de dispositifs',
  link_show_users: 'Utilisateurs',
  link_dashboard: 'Tableau de bord',
  link_reports: 'Rapports',
  link_billing_report: 'Rapport de facturation',

  link_all_devices: 'Tous les dispositifs',
  link_readings: 'Lectures',
  link_flow: 'Débit',
  link_graph: 'Graphique',
  link_new_graph: 'Graphique instantané',
  link_pump_control: 'Contrôle de la pompe',
  link_relay_control: 'Contrôle du relais',
  link_alarm_config: 'Configurer les alarmes',
  link_statistics: 'Statistiques',
  link_customer: 'Données du client',
  link_meter: 'Données du compteur',
  link_config: "Configurer l'entrée",
  link_initial_config: 'Configurer la communication',
  link_sensor_config: 'Configurer le capteur',
  link_sensors_and_network_config: 'Capteurs et réseau',
  link_export: 'Exporter les données',
  link_customer_billing_report: 'Rapport de facturation des clients',
  link_flow_generator: 'Générateur de débit',
  link_commands: 'Envoyer des commandes',
  link_copy_config: 'Copier la configuration',
  switch_to_new_menu_label: 'Utiliser le nouveau menu',
  switch_to_old_menu_label: "Utiliser l'ancien menu",

  link_profile: 'Profil',
  link_language: 'Langue',
  link_sign_out: 'Déconnexion',

  chip_early_access: 'Accès rapide',

  pulse_sensor_1: 'Capteur de pulsations 1',
  pulse_sensor_2: 'Capteur de pulsations 2',
  analog_sensor_1: 'Capteur analogique 1',
  analog_sensor_2: 'Capteur analogique 2',
  analog_sensor_3: 'Capteur analogique 3',
  analog_sensor_4: 'Capteur analogique 4',
  analog_sensor_5: 'Capteur 1',
  analog_sensor_6: 'Capteur 2',
  analog_sensor_7: 'Capteur 3',
  analog_sensor_8: 'Capteur 4',
  on_off_sensor_1: 'Capteur on/off 1',
  on_off_sensor_2: 'Capteur on/off 2',
  on_off_sensor_3: 'Relais on/off 1',
  on_off_sensor_4: 'Relais on/off 2',

  device_icon: 'Icône du dispositif',
  sensor_icon: 'Icône du capteur',
  icon_no_icon: "Pas d'icône",
  icon_barometer: 'Baromètre',
  icon_door_closed: 'Porte fermée',
  icon_door_open: 'Porte ouverte',
  icon_electricity: 'Électricité',
  icon_energy_meter: "Compteur d'énergie",
  icon_gas_flow_meter: 'Compteur de débit de gaz',
  icon_gas: 'Gaz',
  icon_generic_particle_meter: 'Compteur de particules générique',
  icon_generic: 'Générique',
  icon_heat_meter: 'Compteur de chaleur',
  icon_humidity: 'Humidité',
  icon_leaf_moisture: 'Humidité des feuilles',
  icon_level_metering: 'Mesure de niveau',
  icon_pivot: 'Pivot',
  icon_pressure: 'Pression',
  icon_radiation: 'Radiation',
  icon_rain: 'Pluie',
  icon_soil_moisture: 'Humidité du sol',
  icon_temperature: 'Température',
  icon_water_meter: "Compteur d'eau",
  icon_water: 'Eau',
  icon_wind_direction: 'Direction du vent',
  icon_wind_speed: 'Vitesse du vent',
  icon_pump: 'Pompe',
  icon_apartment: "Immeuble d'appartements",
  icon_base_station: 'Station de base',
  icon_clinic: 'Clinique',
  icon_hospital: 'Hôpital',
  icon_house: 'Maison',
  icon_mayor_office: 'Mairie',
  icon_police_station: 'Commissariat de police',
  icon_public_restroom: 'Toilettes publiques',
  icon_stadium: 'Stade',
  icon_controller: 'Icône du contrôleur',
  icon_gas_station: 'Icône de la station-service',

  very_low_level_alarm: 'Alarme de niveau très bas',
  low_level_alarm: 'Alarme de niveau bas',
  high_level_alarm: 'Alarme de niveau élevé',
  very_high_level_alarm: 'Alarme de niveau très élevé',

  sensor_conversion_formula: 'Formule de conversion du capteur',
  delete_old_counters: 'Supprimer les anciens rapports',
  device_config_select_record_period: "Sélectionner la période d'enregistrement",
  device_config_select_transmission_period: 'Sélectionner la période de transmission',

  devices_table_number: 'Numéro',
  devices_table_name: 'Nom',
  devices_table_device_number: 'Numéro du dispositif',
  devices_table_device_name: 'Nom du dispositif',
  devices_table_business_partner: 'Partenaire commercial',
  devices_table_quarter: 'Trimestre',
  devices_table_address: 'Adresse',
  devices_table_error: 'Impossible de charger les dispositifs',
  devices_table_no_devices_found: 'Aucun dispositif trouvé',
  devices_table_description: 'Description',
  devices_column_header_search: 'Rechercher...',

  alarm_config_header: 'Configurer les alarmes',
  alarm_config_notification_email: 'E-mails de notification',
  alarm_config_notification_email_place_holder: 'Liste des e-mails (séparés par des virgules)',
  alarm_config_missed_transmission_email: 'Adresses e-mail pour les transmissions manquées',
  alarm_config_missed_transmission_email_place_holder:
    'Liste des e-mails pour les transmissions manquées (séparés par des virgules)',
  alarm_config_high_consumption_threshold:
    'Seuil de consommation élevée pour la période de transmission {{transmission_every}} {{transmission_period}} [{{units}}]',
  alarm_config_low_flow_alarm_threshold: "Seuil d'alarme de faible débit [{{units}}]",
  alarm_config_high_flow_alarm_threshold: "Seuil d'alarme de débit élevé [{{units}}]",
  alarm_config_non_zero_consumption_time_range: 'Plage horaire pour consommation non nulle',
  alarm_config_high_low_flow_time_range: 'Plage horaire pour débit élevé/faible',
  alarm_config_start_time: 'Heure de début',
  alarm_config_end_time: 'Heure de fin',
  alarm_config_flow_calc_time_base: 'Base de temps pour calcul du débit',
  alarm_config_saved_msg: "Configuration de l'alarme enregistrée.",

  alarm_config_non_zero_consumption_time_range_error:
    'Une heure de début et une heure de fin sont nécessaires',

  alarms_title: 'Alarmes',
  alarms_title_with_dev_num: "Alarmes, numéro de l'appareil : {{device_number}}",
  alarms_type: "Type d'alarme",
  alarms_active_status: 'Activité',
  alarms_active_dropdown_label: 'Actif',
  alarms_inactive_dropdown_label: 'Inactif',

  device_battery_title: 'Niveau de batterie',
  device_battery_voltage: 'Tension, mV',
  device_battery_level: 'Niveau, %',

  device_signal_title: 'Intensité du signal',

  device_counters_graph_header: 'Graphique de consommation',

  device_instant_graph_header: 'Graphique instantané',

  devices_signal_title: 'Signal des dispositifs',
  devices_signal_level: 'Niveau de signal',
  devices_signal_level_percent: 'Niveau, %',

  devices_battery_title: 'Niveau des batteries',
  devices_battery_level: 'Niveau de la batterie, %',
  devices_battery_voltage: 'Tension, mV',

  reports_export_title: 'Exportation des lectures',

  device_counter_export_title:
    "Exporter les compteurs des dispositifs, numéro de l'appareil : {{device_number}}",

  device_config_record_and_transmission_period: "Périodes d'enregistrement et de transmission",
  device_config_record_period: "Période d'enregistrement",
  device_config_transmission_period: 'Période de transmission',
  device_config_start_setup: 'Démarrer la configuration',
  device_config_setup_message:
    'Configuration enregistrée. Passez maintenant un aimant sur le côté du dispositif.',
  device_config_header: 'Configurer le dispositif',
  device_config_config_date: 'Date de configuration : {{date}}',
  device_config_config_status: 'Statut',
  device_config_config_status_configured: 'Synchronisé',
  device_config_config_status_not_configured: 'Non synchronisé',
  device_config_time_zone: 'Fuseau horaire',
  device_config_pulse_per: '{{x}} par impulsion',
  device_config_sensor_type: 'Type de capteur',
  device_config_meter_type: 'Type de compteur',
  device_config_select_time_zone: 'Sélectionnez le fuseau horaire',
  device_config_pulse_coef_not_changed_message:
    'Avertissement : Le "coefficient d\'impulsion" personnalisé reste inchangé malgré les changements dans la "Lecture initiale"',
  device_config_does_not_support_this_model:
    "Ce modèle d'appareil n'est pas pris en charge sur cette page.",

  device_digits_fraction: 'Décimales',
  device_digits_digits: 'Unités',
  device_digits_initial_reading: 'Lecture initiale',
  device_digits_alert_too_many_total_digits: 'Le nombre total de chiffres doit être <= 9',
  device_digits_alert_too_small_total_digits: 'Le nombre total de chiffres doit être >= 4',
  device_digits_alert_too_many_total_fraction:
    'Le nombre de chiffres après la virgule doit être <= 3 et le nombre total de chiffres doit être <= 9',
  device_digits_alert_too_small_total_fraction:
    'Le nombre de chiffres après la virgule doit être >= 0 et le nombre total de chiffres doit être >= 4',

  device_transmissions_at: "Transmissions de l'appareil au {{query_date}}",
  device_transmissions_last_transmission_short: 'Dernière transmission',
  device_transmissions_next_transmissions_short: 'Prochaine transmission',
  device_transmissions_days: 'Jours',
  device_transmissions_transmissions_short: 'Transm',
  device_transmissions_count: 'Nombre',
  device_transmissions_fail: 'Échouer',
  device_transmissions_customer: 'Client',
  device_transmissions_address: 'Adresse',
  device_transmissions_ok: 'OK',

  link_device_link_device_to_my_account: "Lier l'appareil à mon compte",
  link_device_device_number: "Numéro de l'appareil",
  link_device_unlink_device: "Délier l'appareil",
  link_device_with_device_number: `Lier l\'appareil : {{device_number}}`,
  unlink_device_with_device_number: `Délier l\'appareil : {{device_number}}`,
  link_device_devices_counts:
    'Appareils liés : {{linked_devices_count}} / {{allowed_devices_count}}',
  link_device_success_message: 'Appareil associé avec succès',
  unlink_device_success_message: 'Appareil dissocié avec succès de : ',
  link_device_enter_device_number_error: "Entrez un numéro d'appareil",
  link_device_select_company_error: 'Sélectionnez une entreprise',
  link_device_default_error: "Un problème est survenu lors du lien/délien de l'appareil",
  link_device_company_not_found_error: 'Entreprise non trouvée',
  link_device_device_not_found_error: 'Appareil {{device_number}} non trouvé',
  link_device_device_not_linked_error: 'Appareil {{device_number}} non lié',
  link_device_device_already_linked_error: 'Appareil {{device_number}} déjà lié',
  link_device_device_limit_reached_error: "Limite d'appareils atteinte",
  link_device_operation_not_allowed_error: 'Opération non autorisée',
  link_device_unlink_devices_for_sub_accounts: 'Dissocier l’appareil pour tous les sous-comptes',
  link_device_unlink_devices_for_sub_accounts_tooltip:
    'Si coché, l’appareil sera dissocié du compte sélectionné et de tous les sous-comptes. Si non coché, l’appareil sera dissocié uniquement pour le compte sélectionné.',

  device_search_device_number: "Numéro de l'appareil",

  device_manager_create_or_delete_device: "Créer ou supprimer l'appareil : {{device_number}}",
  device_manager_device_number: "Numéro de l'appareil",
  device_manager_device_model: "Modèle de l'appareil",
  device_manager_sw_version_validation:
    'Le format requis est X.X.X ou X.X.X- et X.X.X_ suivi de caractères, où X est un chiffre',
  device_manager_hw_version_validation: 'Le format requis est X.X.X où X est un chiffre',
  device_manager_software_version: 'Version du logiciel',
  device_manager_hardware_version: 'Version du matériel',

  dashboard_alarms: 'Alarmes',
  dashboard_batteries: 'Batteries',
  dashboard_signal: 'Signal',
  dashboard_transmission: '{{alarm_type}} ({{alarm_count}})',
  dashboard_chart_battery_level: 'Niveau de la batterie',
  dashboard_chart_signal_level: 'Niveau du signal',
  dashboard_chart_number_of_devices: "Nombre d'appareils",
  dashboard_select_company_header: 'Sélectionnez un compte pour afficher le tableau de bord',
  dashboard_select_company_from: 'Depuis ici : ',
  dashboard_select_company_accounts_page_link: 'Comptes',

  alarm_type_no_zero_consumption: 'Consommation constante',
  alarm_type_high_consumption: 'Consommation élevée',
  alarm_type_high_flow: 'Débit élevé',
  alarm_type_missed_transmission: 'Transmission manquée',
  alarm_type_low_flow: 'Débit faible',
  alarm_type_all_zero_consumption: 'Aucune consommation',
  alarm_type_device_removed: 'Appareil supprimé',
  alarm_type_on_off_alarm: 'Alarme marche/arrêt',
  alarm_type_low_level: 'Alarme de niveau bas',
  alarm_type_high_level: 'Alarme de niveau élevé',
  alarm_type_tamper: 'Intervention',
  alarm_type_oversized: 'Surdimensionné',
  alarm_type_undersized: 'Sous-dimensionné',
  alarm_type_low_battery: 'Batterie faible',
  alarm_type_blockage: 'Obstruction',
  alarm_type_burst: 'Fuite importante',
  alarm_type_reserve_flow: 'Flux inversé',

  device_counter_graph_min: 'Min : {{min}}',
  device_counter_graph_max: 'Max : {{max}}',
  device_counter_graph_avg: 'Moyenne : {{avrg}}',
  device_counter_graph_total: 'Total : {{total}}',
  device_counter_graph_average: 'Moyenne :',
  device_counter_graph_no_data: 'Aucune donnée',
  device_counter_graph_cant_calculate_flow_analog:
    'Impossible de calculer le débit pour un capteur analogique !',
  device_counter_graph_must_have_digital_sensor: 'Un capteur numérique doit être activé.',

  customer_info_header: 'Informations sur le client',
  customer_info_business_partner_number: 'Compte',
  customer_info_contact_data: 'Données de contact',
  customer_info_contract_number: 'Rôle de lecture',
  customer_info_name: 'Nom du client',
  customer_info_description: 'Description',
  customer_info_billing_group: 'Groupe de facturation',
  customer_info_municipality: 'Ville',
  customer_info_quarter: 'Région',
  customer_info_street: 'Rue',
  customer_info_block: 'Bloc',
  customer_info_entr: 'Appartement',
  customer_info_customer_type: 'Type de client',
  customer_info_customer_type_commercial: 'Commercial',
  customer_info_customer_type_domestic: 'Domestique',
  customer_info_customer_type_block_of_flats: 'Copropriété',
  customer_info_customer_type_budget: 'Relocalisateur',

  meter_info_meter_info: 'Informations du compteur : {{device_number}}',
  meter_info_factory_number: 'Numéro de série du compteur',
  meter_info_brand: 'Marque',
  meter_info_enter_diameter: 'Entrez le diamètre',
  meter_info_mount_date: "Date d'installation",
  meter_info_meter_info_saved: 'Informations du compteur enregistrées.',

  device_initial_config_header: 'Configuration initiale du dispositif',
  device_initial_device_model: "Modèle de l'appareil",
  device_initial_config_address: 'Adresse',
  device_initial_config_port: 'Port',
  device_initial_config_resolve_address: "Résoudre l'adresse (utiliser DNS)",
  device_initial_config_simcard_provider: 'Fournisseur de carte SIM',
  device_initial_config_simcard_number: 'Numéro de carte SIM',
  device_initial_config_bands: 'Bandes',
  device_initial_config_OTAA_appkey: "OTAA - clé d'application",
  device_initial_config_OTAA_deveui: 'OTAA - deveui',
  device_initial_config_OTAA_appeui: 'OTAA - appeui',
  device_initial_config_ABP_appskey: "ABP - clé d'application",
  device_initial_config_ABP_nwkskey: 'ABP - clé de réseau',
  device_initial_config_ABP_devaddr: "ABP - adresse de l'appareil",
  device_initial_config_ABP_deveui: 'ABP - deveui',
  device_initial_config_lora_app_mode: "Mode d'application",
  device_initial_config_app_id: "ID de l'application",
  device_initial_config_profile_id: 'ID du profil',
  device_initial_config_platform_name: 'Nom de la plateforme',
  device_initial_config_thingslog_lora_platform: 'Plateforme ThingsLog LoRa',
  device_receive_data_binary: 'Recevoir des données binaires',
  device_send_data_binary: 'Envoyer des données binaires',
  device_mqtt_config: 'Configuration MQTT',
  device_mqtt_config_qos: 'Qualité de service',
  device_mqtt_config_username: "Nom d'utilisateur",
  device_mqtt_config_password: 'Mot de passe',
  device_mqtt_config_certificate: 'Certificat',
  device_initial_config_apn_username: "Nom d'utilisateur APN",
  device_initial_config_apn_password: 'Mot de passe APN',
  device_initial_config_gprs_radio_mode: 'Mode radio GPRS',
  device_initial_config_grps_radio_mode_auto_option: 'Automatique',
  device_initial_config_net_attached_delay: 'Délai de transmission',
  device_initial_config_preferred_network_type: 'Type de réseau préféré',
  device_initial_config_preferred_network_default_option: 'Par défaut',
  device_initial_config_mcc_mnc: 'MCC-MNC',
  device_initial_config_slave_devices: 'Dispositifs esclaves',
  device_initial_config_modbus_address: 'Adresse Modbus',

  sensor_config_header: 'Configuration du capteur',
  sensor_config_name: 'Nom',
  sensor_config_sensor_name: 'Nom du capteur',
  sensor_config_sensor_icon: 'Icône du capteur',
  sensor_config_description: 'Description',
  sensor_config_model: 'Modèle',
  sensor_config_serial_number: 'Numéro de série',
  sensor_config_manufacturer: 'Fabricant',
  sensor_config_diameter: 'Diamètre',
  sensor_config_price: 'Prix',
  sensor_config_currency: 'Devise',
  sensor_config_port_type: 'Type de port',
  sensor_config_port_type_digital_input_port: "Port d'entrée numérique",
  sensor_config_port_type_analog_input_port: "Port d'entrée analogique",
  sensor_config_port_type_pulse_s0_input_port: "Port d'entrée d'impulsion S0",
  sensor_config_port_type_pulse_input_port: "Port d'entrée d'impulsions",
  sensor_config_port_type_on_off_input_port: "Port d'entrée marche/arrêt",
  sensor_config_port_type_on_off_output_port: 'Port de sortie marche/arrêt',
  sensor_config_port_conversion_formula: 'Formule de conversion du port',
  sensor_config_formula: 'Formule',
  sensor_config_offset: 'Décalage',
  sensor_config_sensor_index_tooltip: 'Indice du capteur : {{sensor_index}}',
  sensor_config_visible: 'Visibilité',
  sensor_config_order_number: 'Numéro de commande',
  sensor_config_visible_true: 'Visible',
  sensor_config_visible_false: 'Invisible',
  sensor_config_use_in_sensor_aggregation: "Utilisation dans l'agrégation des capteurs",
  sensor_config_use_in_sensor_aggregation_true: 'Utiliser',
  sensor_config_use_in_sensor_aggregation_false: 'Ne pas utiliser',
  sensor_config_value_labels: 'Étiquettes de valeurs',
  sensor_config_label_for_value_zero: 'Étiquette pour la valeur "0"',
  sensor_config_label_for_value_one: 'Étiquette pour la valeur "1"',
  sensor_config_inverted_read_state_value: 'État de lecture inversé',
  sensor_config_inverted_read_state_value_true: 'Inversé',
  sensor_config_inverted_read_state_value_false: 'Non inversé',
  sensor_config_register_address: 'Adresse du registre',
  sensor_config_data_type: 'Type de données',
  sensor_config_count: 'Nombre',
  sensor_config_number_bytes: "Nombre d'octets",
  sensor_config_particle_size: 'Taille des particules',
  sensor_config_power: 'Puissance',
  sensor_config_measurement_type: 'Type de mesure',
  sensor_config_measurement_group: 'Groupe de mesure',
  tasmota_generic_digital_sensor: 'Capteur numérique générique Tasmota',
  tasmota_alarm_input_sensor: "Capteur d'entrée d'alarme Tasmota",
  tasmota_generic_analog_sensor: 'Capteur analogique générique Tasmota',

  device_counter_statistics_statistics_id:
    "Statistiques, numéro de l'appareil : {{device_number}}, {{description}}",
  device_counter_statistics_quarter: 'Localisation',
  device_counter_statistics_flow: 'Débit',
  device_counter_statistics_hour: 'Heure',
  device_counter_statistics_quantity: 'Quantité',
  device_counter_statistics_value: 'Valeur',
  device_counter_statistics_digital_ports_must_be_present:
    "Un port d'entrée d'impulsion doit être connecté au capteur d'un compteur d'eau pour afficher ce tableau.",
  device_counter_statistics_name_must_have_initialized_value:
    "Les paramètres Q3, R et Diamètre sont nécessaires pour l'entrée d'impulsion connectée au capteur du compteur d'eau.",

  company_manager_title: 'Créer ou supprimer un compte : {{company_name}}',
  company_manager_account_name: 'ID du compte',
  company_manager_solution: 'Type de projet',
  company_manager_solution_option_none: 'Non assigné',
  company_manager_solution_option_agri: 'Agriculture',
  company_manager_solution_option_air_quality: "Qualité de l'air",
  company_manager_solution_option_street_lights: 'Éclairage public',
  company_manager_solution_option_pump_station: 'Station de pompage',
  company_manager_license: 'Licence',
  company_manager_account: 'Compte',
  company_manager_create_company: 'Créer une entreprise',
  company_manager_create_company_error:
    'Un problème est survenu lors de la création de votre entreprise',
  company_manager_license_info_box:
    "L'ajout de la licence nécessite un identifiant de compte valide. Veuillez vérifier l'exactitude de votre identifiant de compte avant de l'inclure dans le processus de création.",
  company_manager_valid_from: 'Valide à partir du',
  company_manager_valid_to: "Valide jusqu'au",
  company_manager_solution_type: 'Type de solution',
  company_manager_contact_id: 'ID de contact',
  company_manager_account_id: 'ID de compte',
  company_manager_license_type: 'Type de licence',
  company_manager_timezone: 'Fuseau horaire',
  company_manager_country: 'Pays',
  company_manager_countries_error: 'Impossible de charger les pays',
  company_manager_timezones_error: 'Impossible de charger les fuseaux horaires',
  company_manager_account_info: 'Informations sur le compte',
  company_manager_address: 'Adresse',
  company_manager_city: 'Ville',
  company_manager_municipality: 'Municipalité',
  company_manager_area: 'Région',
  company_manager_zip_code: 'Code postal',
  company_manager_vat_number: 'Numéro de TVA',

  company_manager_license_per_device: 'Par appareil',
  company_manager_license_per_reading: 'Par rapport',
  company_manager_license_per_sensor: 'Par capteur',
  company_manager_license_per_poc: 'Par Preuve de Concept (POC)',
  company_manager_license_per_remote_node: 'Par nœud distant',

  company_manager_creating_account: 'Création du compte : {{company_name}}',
  company_manager_deleting_account: 'Suppression du compte : {{company_name}}',
  company_manager_account_created: 'Compte créé avec succès',
  company_manager_account_deleted: 'Compte supprimé avec succès',

  company_manager_update_company: 'Mettre à jour le compte',
  company_manager_delete_company: 'Supprimer le compte',
  company_manager_actions: 'Actions',
  company_manager_delete_modal_description:
    'Êtes-vous sûr de vouloir supprimer ce compte ? Cette action est irréversible.',
  company_manager_confirm_button: 'Confirmer',
  company_manager_cancel_button: 'Annuler',
  company_manager_delete_company_error_message:
    "Une erreur s'est produite lors de la suppression du compte",
  company_manager_update_btn: 'Mettre à jour',
  company_manager_child_company_warning_message: "Création d'un sous-compte de",
  company_manager_current_logged_in_company: 'le compte sur lequel vous êtes actuellement connecté',
  company_manager_current_selected_company: 'le compte que vous avez actuellement sélectionné',
  accounts_child_companies: 'Comptes secondaires',
  accounts_contactRef: 'ID de contact',
  accounts_accountRef: 'ID de compte',
  company_manager_accountRef_is_disabled_because_of_license_type:
    "L'ID du compte est désactivé en raison d'une licence POC",
  accounts_none: 'Aucun',
  company_manager_update_company_success: 'Compte mis à jour avec succès',
  company_manager_update_company_error:
    "Une erreur s'est produite lors de la mise à jour du compte",

  license_max_readings: 'Lectures maximales',
  license_max_devices: 'Appareils maximum',
  license_max_sensors: 'Capteurs maximum',

  license_manager_title: 'Mettre à jour la licence : {{company_name}}',
  license_manager_license: 'Licence',
  license_manager_license_updated: 'Licence mise à jour',

  companies_title: "ID de l'entreprise : {{company_number}}",
  companies_table_name: 'Nom',
  companies_number_of_accounts: 'Nombre de comptes :',
  companies_search: 'Recherche',

  users_title: 'Utilisateurs',
  units_type: "Type d'unités",

  reports_export_format_type: 'Format',

  login_form_forgot_password: 'Mot de passe oublié ?',

  pump_auto_every: 'Automatique toutes les {{secs}} secondes',
  pump_svg_last_updated: 'Dernière mise à jour : {{timestamp}}',
  pump_svg_power_supply: 'Alimentation',
  pump_svg_water_level_controller: "Contrôleur du niveau d'eau",
  pump_svg_pump: 'Pompe',
  pump_svg_level_sensor: 'Capteur de niveau',
  pump_svg_pump_on_sensor: 'Capteur Pompe en marche',
  pump_svg_pump_off_sensor: 'Capteur Pompe arrêtée',
  pump_svg_dry_run_sensor: 'Capteur Marche à sec',

  device_groups_error_please_select_company: 'Veuillez sélectionner une entreprise.',

  device_groups_btn_add: 'Ajouter',
  device_groups_btn_edit: 'Modifier',
  device_groups_btn_delete: 'Supprimer',
  device_groups_btn_cancel: 'Annuler',
  device_groups_tooltip_edit: 'Modifier',
  device_groups_tooltip_delete: 'Supprimer',
  device_groups_color_picker_label: 'Couleur du groupe de dispositifs',

  device_groups_name: 'Nom',
  device_groups_devices: 'Appareils',
  device_groups_view_devices: 'Voir {{count}} appareils',
  device_groups_group_name: 'Nom du groupe',
  device_groups_parent_device_group: 'Groupe parent de dispositifs',
  device_groups_menuitem_none: 'Aucun',
  device_groups_add_device_group: 'Ajouter un groupe de dispositifs',
  device_groups_edit_device_group: 'Modifier le groupe de dispositifs',
  device_groups_delete_device_group: 'Supprimer le groupe de dispositifs',

  device_groups_add_sensor_device: 'Ajouter un capteur et un appareil au groupe',

  device_groups_table_sensor_group_name_header: 'Nom du groupe de capteurs',
  device_groups_table_parent_group_name_header: 'Nom du groupe principal',
  device_groups_table_device_count_header: "Nombre d'appareils",

  device_groups_sensor_table_device_number_header: "Numéro de série de l'appareil",
  device_groups_sensor_table_sensor_name_header: 'Nom du capteur',
  device_groups_sensor_table_sensor_index_header: 'Index de lecture du capteur',
  device_groups_sensor_table_select_message: 'Sélectionner un groupe de capteurs',

  device_groups_sensors_add_modal_device_placeholder: "Numéro de série de l'appareil",
  device_groups_sensors_add_modal_sensor_placeholder: 'Capteur',
  device_groups_sensors_add_modal_device_group_name_required:
    'Le nom du groupe de dispositifs est obligatoire',

  device_groups_sensors_delete_description:
    'La suppression de cette configuration la supprimera définitivement du serveur.',
  device_groups_device_group_delete_description:
    'La suppression du groupe de dispositifs le supprimera définitivement du serveur.',

  device_groups_select_company_header: 'Veuillez sélectionner une entreprise pour continuer',
  device_groups_select_company_from: 'Depuis ici : ',
  device_groups_select_company_accounts_page_link: 'Page des Comptes',

  device_groups_create_device_group_error:
    "Un problème est survenu lors de la création d'un nouveau groupe de dispositifs",
  device_groups_update_device_group_error:
    'Un problème est survenu lors de la mise à jour du groupe de dispositifs',
  device_groups_delete_device_group_error:
    'Un problème est survenu lors de la suppression du groupe de dispositifs',
  device_groups_add_device_sensor_device_group_error:
    "Un problème est survenu lors de l'ajout du capteur",
  device_groups_remove_device_sensor_device_group_error:
    'Un problème est survenu lors de la suppression du capteur',

  /*
   * |==============|
   * | Users Config |
   * |==============|
   */

  //Page
  users_header: 'Utilisateurs',
  users_table_username: "Nom d'utilisateur",
  users_table_first_name: 'Prénom',
  users_table_last_name: 'Nom',
  users_table_company_name: 'Compte',
  users_table_email: 'Adresse e-mail',

  //Filters
  users_filter_username: "Filtrer par nom d'utilisateur",
  users_filter_first_name: 'Filtrer par prénom',
  users_filter_last_name: 'Filtrer par nom',
  users_filter_email: 'Filtrer par e-mail',
  users_filter_company_name: 'Filtrer par compte',
  users_reset_filter_icon: 'Réinitialiser les filtres',
  users_table_is_contact_person: 'Personne de contact',

  //Modals
  users_add_modal_header: 'Ajouter un utilisateur',
  users_update_modal_header: 'Mettre à jour un utilisateur',
  user_remove_modal_icon: 'Supprimer un utilisateur',
  users_remove_modal_header: "Êtes-vous sûr de vouloir supprimer l'utilisateur {{username}} ?",
  users_remove_modal_body:
    "En cliquant sur supprimer, l'utilisateur sélectionné sera définitivement supprimé du serveur.",
  users_is_dashboard_enabled: 'Tableau de bord activé',
  users_is_contact_person: 'Personne de contact',

  //Errors
  users_password_match: 'Les mots de passe ne correspondent pas',
  users_email_blank: "L'e-mail ne peut pas être vide",
  users_load_error: 'Impossible de charger les utilisateurs',
  users_update_error: "Impossible de mettre à jour l'utilisateur",
  users_delete_error: "Impossible de supprimer l'utilisateur",
  users_create_error: "Impossible de créer l'utilisateur",

  // Success
  users_update_success: 'Utilisateur mis à jour avec succès',
  users_delete_success: 'Utilisateur supprimé avec succès',
  users_create_success: 'Utilisateur créé avec succès',
  /*
   * |=====================|
   * | Value Ranges Config |
   * |=====================|
   */

  // Buttons & tooltips
  value_ranges_btn_add: 'Ajouter',
  value_ranges_btn_defaults: 'Valeurs par défaut',
  value_ranges_line_color: 'Couleur de la ligne',
  value_ranges_node_color: 'Couleur du nœud',
  value_ranges_foreground_color: 'Couleur de premier plan',
  value_ranges_background_color: 'Couleur de fond',
  value_ranges_loading: 'Chargement...',
  value_ranges_nothing_to_show: 'Rien à afficher',

  //Page
  value_ranges_header: 'Événements de plage de valeurs',
  value_ranges_trigger: 'Déclencheur',
  value_ranges_notifications: 'Notifications',
  value_ranges_before: 'Avant',
  value_ranges_after: 'Après',
  value_ranges_disabled: 'Désactivé',

  //Modals
  value_ranges_add_event: 'Ajouter un événement',
  value_ranges_edit_event: "Modifier l'événement",
  value_ranges_add_default_events: 'Ajouter des événements par défaut',
  value_ranges_remove_modal_header: 'Êtes-vous sûr de vouloir supprimer la plage de valeurs',
  value_ranges_remove_modal_body:
    'En cliquant sur supprimer, la plage de valeurs sélectionnée sera définitivement supprimée.',

  //Form
  value_range_form_name: "Nom de l'événement",
  value_range_form_function: 'Fonction',
  value_range_form_sensor: 'Capteur',
  value_range_form_description: 'Description',
  value_range_form_min: 'Min',
  value_range_form_max: 'Max',
  value_range_form_start_day: 'Jour de début',
  value_range_form_start_month: 'Mois de début',
  value_range_form_start_time: 'Heure de début',
  value_range_form_end_day: 'Jour de fin',
  value_range_form_end_month: 'Mois de fin',
  value_range_form_end_time: 'Heure de fin',
  value_range_form_enable_events: 'Activer les événements ?',
  value_range_form_select_color: 'Couleur sélectionnée',
  value_range_form_notification_email: 'E-mail',
  value_range_form_notification_push_notification: 'Notification push',
  value_range_form_notification_sms: 'SMS',
  value_range_form_notification_push: 'Notification push',
  value_range_form_notification_call: 'Appel',
  value_range_form_line_custom_color: 'Couleur de ligne personnalisée',
  value_range_form_marker_custom_color: 'Couleur de marqueur personnalisée',
  value_range_form_no_notifications: 'Pas de notifications',

  //Errors
  value_range_error_add: "Impossible d'ajouter l'événement",
  value_range_error_edit: "Impossible de modifier l'événement",
  value_range_name_is_required: 'Le nom est requis',
  value_range_name_already_exists:
    "Le nom de l'événement existe déjà pour cet appareil et ce capteur",
  value_range_min_greater_than_max: 'Le minimum ne peut pas être supérieur au maximum',
  value_range_min_equal_to_max: 'Le minimum ne peut pas être égal au maximum',
  value_range_name_incorrect_time: "Format de l'heure incorrect",
  value_range_min_max_is_required: 'Le minimum ou le maximum est requis',
  value_range_error_occurred: "Une erreur s'est produite",
  value_range_start_incorrect_time:
    "L'heure de début a un format incorrect. Format correct : HH:mm. Correct : 01:00. Incorrect : 1:00.",
  value_range_end_incorrect_time:
    "L'heure de fin a un format incorrect. Format correct : HH:mm. Correct : 01:00. Incorrect : 1:00.",
  value_range_start_day_missing_start_month: 'Le jour de début doit avoir un mois de début',
  value_range_end_day_missing_end_month: 'Le jour de fin doit avoir un mois de fin',
  value_range_start_month_missing_start_day: 'Le mois de début doit avoir un jour de début',
  value_range_end_month_missing_end_day: 'Le mois de fin doit avoir un jour de fin',
  value_range_mixed_alphabets_error:
    "L'utilisation d'alphabets mixtes a été détectée, veuillez utiliser une seule langue",

  /*
   * |==========|
   * | Commands |
   * |==========|
   */

  // Command State
  commands_command_state_pending: 'En attente',
  commands_command_state_sent: 'Envoyé',
  commands_command_state_cancelled: 'Annulé',
  commands_command_state_status_ok: 'OK',
  commands_command_state_status_nok: 'Non OK',
  commands_command_state_unknown: 'Inconnu',
  commands_command_state_timeout: "Délai d'attente dépassé",

  // Command Type
  commands_command_type_relay_switch: 'Interrupteur de relais',
  commands_command_type_single_relay_switch: 'Interrupteur de relais unique',
  commands_command_type_relay_switch_with_delay: 'Interrupteur de relais avec délai',
  commands_command_type_relay_statuses: 'Statuts des relais',
  commands_command_type_send_config_over_mqtt: 'Envoyer configuration via MQTT',
  commands_command_type_send_date_over_mqtt: 'Envoyer la date via MQTT',
  commands_command_type_modbus: 'Modbus',
  commands_command_type_tasmota_relay_switch: 'Commande de relais Tasmota',

  // Errors
  commands_error_missing_device_number: "Numéro de série de l'appareil manquant ou incorrect.",

  // Buttons
  commands_btn_send_command: 'Envoyer commande',
  commands_btn_refresh: 'Actualiser',
  commands_btn_submit: 'Soumettre',

  // Tooltips
  commands_tooltip_edit: 'Modifier',

  // Popups
  commands_send_command: 'Envoyer commande',
  commands_edit_command: 'Modifier commande',
  commands_command_type: 'Type de commande',
  commands_parameters: 'Paramètres',
  commands_parameter_name: 'Nom du paramètre',
  commands_parameter_value: 'Valeur du paramètre',

  // Page
  commands_header: 'Commandes',
  commands_type: 'Type',
  commands_status: 'Statut',
  commands_relay_1_status: 'Statut du relais 1',
  commands_relay_2_status: 'Statut du relais 2',
  commands_created: 'Créé',
  commands_sent: 'Envoyé',
  commands_executed: 'Exécuté',
  commands_relay_status: 'Statut du relais',
  commands_relay_index: 'Indice du relais',
  commands_device_number_slave: "Numéro de l'appareil secondaire",
  commands_sensor_index: 'Indice du capteur',

  /*
   * |=============|
   * | Copy Config |
   * |=============|
   */

  // Page
  copy_config_copy_config: 'Copier la configuration',
  copy_config_to_device_number_with_name_absent: "Vers l'appareil : {{device_number}}",
  copy_config_to_device_number_with_name_present:
    "Vers l'appareil : {{device_name}} ({{device_number}})",
  copy_config_from_device_number: "De l'appareil numéro",
  copy_config_copy_config_type_label: 'Type de copie de la configuration',
  copy_config_existing_device_option: 'Dispositif existant',
  copy_config_new_device_option: 'Nouveau dispositif',
  copy_config_should_copy_label: 'Doit copier :',
  copy_config_should_copy_config_option: 'Configuration',
  copy_config_should_copy_value_ranges_option: 'Plages de valeurs',

  // Buttons
  copy_config_btn_copy: 'Copier',

  //Toast
  copy_config_toast_copied_success: "La configuration de l'appareil a été copiée avec succès.",

  /*
   * |============|
   * | Statistics |
   * |============|
   */
  statistics_header: 'Statistiques',
  statistics_header_hours: 'Heures',
  statistics_header_quantity: 'Quantité',
  statistics_header_value: 'Valeur',
  statistics_quantity_minimum: 'Minimum',
  statistics_quantity_maximum: 'Maximum',
  statistics_quantity_average: 'Moyenne',
  statistics_quantity_total: 'Total',
  statistics_quantity_hours: 'Heures',
  statistics_quantity_counters: 'Compteurs',

  // Error
  statistics_error_definition_for_q3:
    'Le capteur {{name}} contient une définition pour q3. Consultez la page de configuration du capteur.',
  statistics_eror_definition_for_diameter:
    'Le capteur {{name}} contient une définition pour le diamètre. Consultez la page de configuration du capteur.',
  statistics_error_definition_for_r:
    'Le capteur {{name}} contient une définition pour r. Consultez la page de configuration du capteur.',
  statistics_error_no_water_meters_found:
    "Aucun compteur d'eau trouvé. Visitez la page de configuration de l'appareil.",

  /*
   * |=====|
   * | Map |
   * |=====|
   */
  map_add_device: 'Ajouter {{device}}',
  map_edit_device: 'Modifier {{device}}',
  map_lat: 'Latitude',
  map_long: 'Longitude',
  map_coordinates: 'Coordonnées',
  map_polygon: 'Polygone',
  map_linked_in_polygons: 'Contenu dans les polygones :',
  map_loading: 'Chargement...',

  // Button
  map_btn_link_device: "Lier l'appareil",
  map_btn_submit: 'Envoyer',

  // Error
  map_err_add_device_to_map: "Impossible d'ajouter l'appareil à la carte.",
  map_err_change_location: "Impossible de modifier l'emplacement.",
  map_err_link_polygon: 'Impossible de lier au polygone.',

  // Device Modal
  map_btn_get_curr_location: 'Obtenir la position actuelle',
  map_geolocation_not_supported: "La géolocalisation n'est pas prise en charge par ce navigateur.",
  map_lat_validation_message: 'Latitude non valide',
  map_long_validation_message: 'Longitude non valide',
  map_invalid_location: 'Emplacement invalide',
  map_invalid_coordinate_input:
    'Entrée de coordonnées non valide. Elle doit être "lat, long", ex : 42.123, 23.123',

  // Set Coordinates Modal
  map_coordinates_modal_btn_label: 'Définir les coordonnées',
  map_coordinates_modal_title: 'Définir les coordonnées',
  map_coordinates_modal_target_option_self: 'Pour soi-même',
  map_coordinates_modal_target_option_company: "Pour l'entreprise",
  map_coordinates_modal_target_option_user: "Pour l'utilisateur",
  map_coordinates_modal_select_user_autocomplete_label: "Nom d'utilisateur",
  map_coordinates_modal_save_for_self_warning_message:
    'Enregistrer définira les coordonnées actuelles comme valeur par défaut pour votre utilisateur actuel. Êtes-vous sûr de vouloir continuer ?',
  map_coordinates_modal_save_for_company_warning_message:
    'Enregistrer définira les coordonnées actuelles comme valeur par défaut pour chaque utilisateur de votre entreprise. Êtes-vous sûr de vouloir continuer ?',
  map_coordinates_modal_save_for_user_warning_message:
    "Enregistrer définira les coordonnées actuelles comme valeur par défaut pour l'utilisateur sélectionné. Êtes-vous sûr de vouloir continuer ?",
  map_coordinates_modal_confirm_btn_label: 'Confirmer',
  map_coordinates_modal_cancel_btn_label: 'Annuler',

  /*
   * |===============|
   * | Sensor Config |
   * |===============|
   */

  flow_time_units: 'Unité de temps',
  flow_measurement_units: 'Unité de mesure',
  flow_configuration_label: 'Configuration du débit',
  flow_coefficient: 'Coefficient',
  sensor_config_sensor_group_name: 'Nom du groupe de capteurs',
  flow_configuration_alert_message: 'Vérifiez les configurations de débit suivantes :\n{{message}}',
  flow_configuration_invalid_sensor_row_message:
    'Indice : {{sensor_index}} avec nom : {{sensor_name}}\n',
  flow_configuration_sensor_name_not_set_label: 'Non défini',

  /*
   * |===================|
   * | Device Prediction |
   * |===================|
   */
  device_prediction: 'Prédiction de dispositifs',
  device_prediction_header: 'Analyse des séries temporelles',
  device_prediction_sensor_name: 'Nom du capteur',
  device_prediction_forward_days: 'Jours futurs',
  device_prediction_backward_days: 'Jours passés',
  device_prediction_generate_btn: 'Générer',
  device_prediction_error: 'Erreur',
  device_prediction_error_msg_json:
    'Un problème est survenu lors de la génération des données de prédiction.',
  device_prediction_error_msg_image:
    "Un problème est survenu lors de la génération de l'image de prédiction.",
  device_prediction_date: 'Date',
  device_prediction_trend: 'Tendance',
  device_prediction_time: 'Heure',
  device_prediction_legend_prediction: 'Prédiction',
  device_prediction_legend_range: 'Plage de prédiction',
  device_prediction_legend_consumption: 'Consommation actuelle',
  device_prediction_help_title: 'Aide',
  device_prediction_help_title_input_guide: "Guide d'entrée",
  device_prediction_help_input_guide_text:
    '<strong> 1. Période </strong> - Les utilisateurs peuvent sélectionner une plage de dates parmi des options prédéfinies ou définir une plage personnalisée. Le système analyse les données de cette période pour calculer des prédictions futures, offrant ainsi des informations précieuses. <br> <strong> 2. Nom du Capteur </strong> – Sélectionnez le capteur pour lequel vous souhaitez voir les prédictions futures. <br> <strong> 3. Chaque </strong> - Utilisé pour des prédictions plus détaillées concernant la granularité du temps. <br> <strong> 4. Jours à venir </strong> - Nombre de jours dans le futur que l\'utilisateur souhaite prédire. <br> <strong> 5. Jours passés </strong> - Nombre de jours affichés dans le graphique et le tableau, à partir du dernier jour de la période sélectionnée. Cela montre les données historiques réelles reçues durant cette période. <br> <strong> 6. Bouton Générer </strong> – En cliquant sur "Générer", le système calcule la consommation en fonction de la configuration sélectionnée et génère des prédictions. <br> <strong> 7. Graphique </strong> – Le graphique affiche les jours passés et à venir, présentant à la fois les données réelles reçues et les données prédites. <br> <strong> 8. Tableau </strong> - Le tableau affiche tous les données de la période jusqu\'à la fin des "jours à venir". Chaque ligne comprend une date spécifique, une heure et une valeur de tendance, indiquant la direction générale des données au fil du temps.',
  device_prediction_help_title_legend: 'Légende',
  device_prediction_help_black:
    '<strong> Couleur Noire </strong> - Représente les données de consommation réelles durant la période sélectionnée.',
  device_prediction_help_red:
    "<strong> Couleur Rouge </strong> - Représente la consommation réelle s'il y a des données en dehors de la période sélectionnée.",
  device_prediction_help_dark_blue:
    '<strong> Couleur Bleu Foncé </strong> - Représente la prédiction pour la période sélectionnée.',
  device_prediction_help_light_blue:
    '<strong> Couleur Bleu Clair </strong> - Représente la plage des valeurs prédites.',
  device_prediction_help_example:
    "Si la date 'DEPUIS' est le 1er juillet et la date 'JUSQU'AU' 31 juillet, avec 'Jours à venir' de 30 et 'Jours passés' de 30, le graphique montre la consommation réelle pour les 30 jours disponibles et les valeurs prédites pour 30 jours à venir.",

  /*
   * |=================|
   * | Counters Export |
   * |=================|
   */

  // Buttons and dropdowns
  counters_export_header: 'Exporter des données',
  counters_readings_header: 'Lectures',
  counters_export_search: 'Rechercher',
  counters_export_download: 'Télécharger',
  counters_export_format: 'Format',

  // Table columns
  counters_export_table_device_name: 'Nom du dispositif',
  counters_export_table_device_number: 'Numéro du dispositif',
  counters_export_table_sensor_index: 'Indice des capteurs',
  counters_export_table_sensor_name: 'Nom du capteur',
  counters_export_table_date: 'Date',
  counters_export_table_counters: 'Lectures',
  counters_export_table_prev_counters: 'Lectures précédentes',
  counters_export_table_dt: 'dt',
  counters_export_table_delta: 'Delta',
  counters_export_error_alert_message:
    "Une erreur s'est produite lors de la génération des données.",
  counter_export_select_devices_label: 'Sélectionner des dispositifs',
  counter_export_select_sensors_label: 'Sélectionner des capteurs',
  counter_export_sensor_index: 'Indice des capteurs : {{sensor_index}}',

  counters_export_maximum_capacity_error_message: "Capacité maximale d'exportation atteinte !",
  counters_export_maximum_capacity_suggestion_message:
    'Réduisez le nombre de dispositifs sélectionnés ou la plage de date.',
  /*
   * |====================|
   * | Consumption Export |
   * |====================|
   */

  // Buttons and dropdowns
  consumption_export_header: 'Rapport',
  consumption_export_search: 'Rechercher',
  consumption_export_download: 'Télécharger',
  consumption_export_format: 'Format',
  consumption_export_aggregation_period: "Période d'agrégation",
  consumption_export_aggregation_options_none: 'Aucun',
  consumption_export_aggregation_options_daily: 'Journalier',
  consumption_export_aggregation_options_weekly: 'Hebdomadaire',
  consumption_export_aggregation_options_monthly: 'Mensuel',
  consumption_export_aggregation_options_yearly: 'Annuel',

  // Table columns
  consumption_export_table_device_number: 'Numéro du dispositif',
  consumption_export_table_sensor_index: 'Indice des capteurs',
  consumption_export_table_address: 'Adresse',
  consumption_export_table_sensor_name: 'Nom du capteur',
  consumption_export_table_first_reading_date: 'Date de la première lecture',
  consumption_export_table_first_reading_value: 'Valeur de la première lecture',
  consumption_export_table_last_reading_date: 'Date de la dernière lecture',
  consumption_export_table_last_reading_value: 'Valeur de la dernière lecture',
  consumption_export_table_delta: 'Delta',
  consumption_export_table_average: 'Moyenne',
  consumption_export_table_consumption_for_period: 'Consommation pour la période',

  /*
   * |=================|
   * | Alarms Export |
   * |=================|
   */

  // Buttons and dropdowns
  alarms_export_header: 'Alarmes',
  alarms_export_search: 'Rechercher',
  alarms_export_refresh: 'Actualiser',
  alarms_export_download: 'Télécharger',
  alarms_export_format: 'Format',

  alarms_return_to_alarms: 'Retour aux alarmes',
  alarms_redirect_to_graph: 'Rediriger vers le graphique',

  // Table columns
  alarms_export_table_date: 'Date',
  alarms_export_table_device_number: 'Numéro du dispositif',
  alarms_export_table_device_name: 'Nom du dispositif',
  alarms_export_table_sensor_index: 'Indice des capteurs',
  alarms_export_table_sensor_name: 'Nom du capteur',
  alarms_export_table_alarm_type: 'Type',
  alarms_export_table_alarm_status: 'État',
  alarms_export_table_description: 'Description',
  alarms_export_chip_status_active: 'Actif',
  alarms_export_chip_status_inactive: 'Inactif',
  alarms_export_switch_title_label: 'Actualisation automatique',
  alarms_export_error_alert_message: "Une erreur s'est produite lors de la génération des données.",
  alarms_export_select_company_alert_message:
    'Veuillez sélectionner une entreprise avant de rechercher des alarmes',
  alarms_export_select_device_or_company_alert_message:
    'Veuillez sélectionner un dispositif ou une entreprise avant de rechercher des alarmes',
  alarms_export_error_too_big_query:
    "La récupération de l'historique pour tous les dispositifs est trop volumineuse. Veuillez sélectionner une entreprise.",

  /*
   * |================|
   * | Inventory page |
   * |================|
   */

  //table
  device_inventory_page_header: 'Inventaire',
  device_inventory_column_header_search: 'Rechercher…',
  device_inventory_description: 'Description',
  device_inventory_device_name: 'Nom du dispositif',
  device_inventory_device_number: 'Numéro du dispositif',
  device_inventory_hardware_version: 'Version du matériel',
  device_inventory_model: 'Modèle',
  device_inventory_software_version: 'Version du logiciel',
  device_inventory_actions: 'Actions',
  nomenclature: 'Nomenclature',

  //modal
  device_inventory_manual: 'Manuel',
  device_inventory_template: 'Modèle',
  device_inventory_add_device: 'Ajouter un dispositif',
  device_inventory_create_device_button: 'Créer un dispositif',
  device_inventory_delete_unlink_modal_body:
    'Cela supprimera définitivement l’appareil choisi ou vous pouvez le dissocier de l’entreprise sélectionnée. L’appareil sera dissocié du compte actuel et de tous les sous-comptes. Si vous souhaitez dissocier l’appareil uniquement du compte actuel, veuillez aller sur la page "Associer un appareil".',
  device_inventory_delete_modal_body: 'Cela supprimera définitivement le dispositif sélectionné.',
  device_inventory_hardware_version_helper: 'Le format requis est X.X.X où X est un nombre',
  device_inventory_update_device_button: 'Mettre à jour le dispositif',
  device_inventory_update_device_title: 'Mise à jour de {{number}}',
  device_inventory_modal_delete_device_title: 'Supprimer le dispositif {{number}}',
  device_inventory_software_version_helper:
    'Le format requis est X.X.X ou X.X.X- et X.X.X_ suivi de caractères, où X est un nombre',
  device_inventory_description_helper: 'Limite de caractères pour la description atteinte',
  device_inventory_device_number_helper:
    'Veuillez entrer 8 caractères contenant uniquement des lettres (A-Z) et des chiffres (0-9).',
  device_inventory_modal_select_action:
    'Sélectionner une action pour le dispositif {{deviceNumber}}',
  device_inventory_reference_device_number: 'Sélectionner un dispositif pour la référence',
  device_inventory_nomenclature_required_for_model: 'La nomenclature est requise pour {{model}}',

  //success
  device_inventory_create_device_success: 'Dispositif créé avec succès',
  device_inventory_update_device_success: 'Dispositif mis à jour avec succès',
  device_inventory_delete_device_success: 'Dispositif supprimé avec succès',
  device_inventory_unlink_device_success: 'Dispositif dissocié avec succès',

  //error
  device_inventory_create_device_error: 'Une erreur est survenue lors de la création du dispositif',
  device_inventory_delete_device_error:
    'Une erreur est survenue lors de la suppression du dispositif',
  device_inventory_device_exists: 'Le dispositif existe déjà',
  device_inventory_empty_device_error: 'Le numéro du dispositif ne peut pas être vide',
  device_inventory_empty_reference_error: 'Sélectionnez un dispositif pour la référence',
  device_inventory_empty_device_name_error: 'Le nom du dispositif ne peut pas être vide',
  device_inventory_empty_hardware_error: 'La version du matériel ne peut pas être vide',
  device_inventory_empty_software_error: 'La version du logiciel ne peut pas être vide',
  device_inventory_error: 'Impossible de charger les dispositifs',
  device_inventory_update_device_error:
    'Une erreur est survenue lors de la mise à jour du dispositif',
  device_inventory_already_exists_error: 'Un dispositif avec ce numéro existe déjà',

  /*
   * |====================|
   * | Transmissions page |
   * |====================|
   */

  //page
  transmissions_page_title: 'Transmissions',
  transmissions_page_refresh: 'Actualiser',
  transmissions_page_export_csv: 'Exporter en CSV',
  transmissions_page_error_message: 'Impossible de charger les transmissions',

  //table
  transmissions_table_device_number: 'Numéro de dispositif',
  transmissions_table_device_name: 'Nom du dispositif',
  transmissions_table_last_transmission: 'Dernière transmission',
  transmissions_table_next_transmission: 'Prochaine transmission',
  transmissions_table_days: 'Jours',
  transmissions_table_transmission: 'Transmission',
  transmissions_table_count: 'Nombre',
  transmissions_table_customer: 'Client',
  transmissions_table_address: 'Adresse',
  transmissions_table_ok: 'OK',
  transmissions_table_devEUI: 'devEUI',
  transmissions_table_simcard_number: 'Numéro de carte SIM',
  transmissions_table_simcard_provider: 'Fournisseur de carte SIM',
  transmissions_table_apn: 'APN',

  /*
   * |===============|
   * | Device Config |
   * |===============|
   */

  // page
  device_config_period: 'Période',
  device_config_every: 'Tous les',

  // error
  device_config_error_record_period_postiive: "La période d'enregistrement doit être positive.",
  device_config_error_transmission_period_positive:
    'La période de transmission doit être positive.',
  device_config_error_record_must_be_greater_than_transmission:
    "La période d'enregistrement ne peut pas être supérieure à la période de transmission.",
  device_config_error_not_divisible:
    "La période d'enregistrement n'est pas divisible par la période de transmission.",
  device_config_error_max_threshold_reached:
    '{{curr}} enregistrements par transmission dépassent le maximum de {{max}}.',

  /*
   * |=======|
   * | Graph |
   * |=======|
   */
  graph_tabs_readings: 'Lectures',
  graph_tabs_prediction: 'Prédiction',
  graph_tabs_average: 'Moyenne',
  graph_tabs_previous: 'Précédent',
  graph_column_sensor: 'Capteur',
  graph_column_index: 'Indice',
  graph_column_device: 'Dispositif',
  graph_column_device_name: 'Nom du dispositif',
  graph_column_date: 'Date',
  graph_column_readings: 'Lectures',
  graph_column_previous_readings: 'Lectures précédentes',
  graph_column_delta: 'Delta',
  /*
   * |===============|
   * | Settings page |
   * |===============|
   */
  settings_page_header: 'Paramètres',
  settings_submit_success_msg: 'Paramètres enregistrés',
  settings_submit_error_msg: "Impossible d'enregistrer les paramètres",
  settings_input_invalid_format_error_msg: 'Format de données invalide',
  settings_certificate_not_found_error: 'Certificat introuvable à cet emplacement',
  settings_add_button: 'Ajouter',
  settings_submit_button: 'Envoyer',
  settings_input_key_label: 'Clé',
  settings_input_type_label: 'Type',
  settings_input_value_label: 'Valeur',
  settings_select_type_string: 'Chaîne',
  settings_select_type_number: 'Nombre',
  settings_select_type_boolean: 'Booléen',
  settings_select_type_object: 'Objet',
  settings_logo_label: 'Logo',
  settings_dashboard_layout_label: 'Disposition du tableau de bord',
  settings_certificate_label: 'Chemin du certificat',
  settings_device_panel_title: 'Titre du panneau de dispositif',
  settings_sensor_panel_title: 'Titre du panneau de capteur',
  settings_header_home_label: 'Libellé accueil (Portail)',
  settings_header_dashboard_label: 'Libellé tableau de bord (Portail)',
  settings_footer_main_label: 'Libellé principal pied de page (Portail)',
  settings_footer_link_label: 'Libellé lien pied de page (Portail)',
  settings_footer_link_url: 'URL du lien pied de page (Portail)',
  settings_alarm_notification_display_period:
    "Période d'affichage des notifications d'alarme (Portail)",
  settings_alarm_notification_display_period:
    "Période d'affichage des notifications d'alarme (heures) (Portail)",

  /* |===========|
   * | New Graph |
   * |===========|
   */

  //page
  new_graph_add_device_group_modal_title:
    'Configuration des capteurs du groupe de dispositifs "Chaque"',
  new_graph_add_device_group_modal_device_group: 'Groupe de dispositifs : ',
  new_graph_add_device_group_modal_sensors_to_add: 'Capteurs à ajouter : ',
  new_graph_add_device_group_modal_every_selector_mode_per_device: 'Par dispositif',
  new_graph_add_device_group_modal_every_selector_mode_per_sensor: 'Par capteur',
  new_graph_add_device_group_modal_sensor_limit_message:
    'Vous ne pouvez pas dépasser la limite de {{graphSensorsLimit}} capteurs pour les graphiques !',
  new_graph_add_device_group_modal_confirm_button: 'Confirmer',
  new_graph_add_device_group_modal_cancel_button: 'Annuler',
  new_graph_select_sensor_heading: 'Sélectionner un capteur',
  new_graph_date_range_selection: 'Sélection de la plage de dates',
  new_graph_selected_sensors_heading: 'Capteurs sélectionnés',
  new_graph_selected_sensors_remove_all_tooltip: 'Tout effacer',
  new_graph_select_company_message: 'Veuillez sélectionner une entreprise',
  new_graph_show_sensors_statistics_label: 'Afficher les statistiques',

  //sensor selection
  new_graph_device_group_label: 'Groupe de dispositifs',
  new_graph_device_label: 'Dispositif',
  new_graph_sensor_label: 'Capteur',

  //date range selection
  new_graph_average_days_label: 'Jours moyens',
  new_graph_time_series_prediction_period_label: 'Période',
  new_graph_auto_refresh_label: 'Actualiser',
  new_graph_period_picker_heading: 'Lectures',
  new_graph_average_days_heading: 'Moyenne',
  new_graph_time_series_prediction_period_heading: 'Prédiction des séries temporelles',
  new_graph_none_option: 'Aucun',
  new_graph_day_option_one: '{{count}} jour',
  new_graph_day_option_other: '{{count}} jours',
  new_graph_week_option_one: '{{count}} semaine',
  new_graph_week_option_other: '{{count}} semaines',
  new_graph_month_option_one: '{{count}} mois',
  new_graph_month_option_other: '{{count}} mois',
  new_graph_year_option_one: '{{count}} année',
  new_graph_year_option_other: '{{count}} années',

  //tooltip
  new_graph_current_value_name: 'Valeur actuelle',
  new_graph_average_value_name: 'Moyenne',
  new_graph_prediction_value_name: 'Prédiction',

  //error
  new_graph_fetch_device_groups_error:
    "Impossible de charger les groupes de dispositifs pour l'entreprise.",
  new_graph_fetch_devices_error: "Impossible de charger les dispositifs pour l'entreprise.",
  new_graph_fetch_device_config_error:
    'Impossible de charger la configuration du dispositif sélectionné.',
  new_graph_fetch_device_ports_config_error:
    'Impossible de charger les ports pour le dispositif sélectionné.',
  new_graph_fetch_device_flow_error:
    'Impossible de charger le flux pour le dispositif {{deviceName}}.',
  new_graph_fetch_device_flow_avg_error:
    'Impossible de charger le flux moyen pour le dispositif {{deviceName}}.',
  new_graph_fetch_device_readings_error: 'Impossible de charger les lectures pour {{deviceName}}.',
  new_graph_fetch_device_readings_avg_error:
    'Impossible de charger les lectures moyennes pour {{deviceName}}.',
  new_graph_fetch_device_on_off_alarms_error:
    'Impossible de charger les alarmes marche/arrêt pour {{deviceName}}.',
  new_graph_fetch_device_prediction_error:
    'Impossible de charger la prédiction pour {{deviceName}}.',
  new_graph_sensor_already_in_state_error: 'Ce capteur a déjà été ajouté à la liste.',

  /* |================|
   * | Billing Report |
   * |================|
   */

  billing_report_header: 'Rapport de facturation',
  billing_report_btn_generate: 'Générer',
  billing_report_device_number: 'Numéro du dispositif',
  billing_report_first_reading_date: 'Date de la première lecture',
  billing_report_last_reading_date: 'Date de la dernière lecture',
  billing_report_readings: 'Lectures',
  billing_report_battery: 'Batterie',
  billing_report_signal: 'Signal',
  billing_report_total: 'Total',

  /* |================|
   * | Profile Page   |
   * |================|
   */
  profile_page_change_logo: 'Changer de logo',
  profile_page_logo_formats_message: 'Formats acceptés : PNG, JPEG',
  profile_page_name: 'Nom',
  profile_page_email: 'E-mail',
  profile_page_language: 'Langue',
  profile_page_password: 'Mot de passe',
  profile_page_change_language: 'Changer de langue',
  profile_page_update_license: 'Mettre à jour la licence',
  profile_page_change_password: 'Changer le mot de passe',
  profile_page_generate: 'Générer',
  profile_page_header: 'Profil',
  profile_page_user_info_label: 'Informations utilisateur',
  profile_page_license_label: 'Licence',
  profile_page_license_type: 'Type de licence',
  profile_page_valid_from: 'Valide à partir de',
  profile_page_valid_to: "Valide jusqu'à",
  profile_page_max_readings: 'Lectures maximales',
  profile_page_current_count: 'Compte actuel',
  profile_page_first_reading: 'Première lecture',
  profile_page_last_reading: 'Dernière lecture',
  profile_page_license_status: 'Statut',
  profile_page_renew_license_button: 'Renouveler la licence',
  profile_page_deactivate_license_button: 'Désactiver la licence',
  profile_page_valid_license: 'Licence valide',
  profile_page_invalid_license: 'Licence invalide',
  profile_page_deactivate_license_success_message: 'Licence désactivée.',
  profile_page_deactivate_license_error_message: 'Impossible de désactiver la licence.',
  profile_page_deactivate_modal_title: 'Désactivation de la licence',
  profile_page_confirm_button: 'Confirmer',
  profile_page_cancel_button: 'Annuler',
  profile_page_deactivate_license_modal_description:
    'Êtes-vous sûr de vouloir désactiver cette licence ?',
  profile_page_activate_license_modal_description:
    'Êtes-vous sûr de vouloir activer cette licence ?',
  profile_page_activate_modal_title: 'Activation de la licence',
  profile_page_license_per_device: 'par dispositif',
  profile_page_license_per_reading: 'par lecture',
  profile_page_license_per_sensor: 'par capteur',
  profile_page_license_per_poc: 'par preuve de concept (POC)',
  profile_page_license_per_remote_node: 'par nœud distant',
  profile_page_license_partial: 'partielle',
  profile_page_logo_file_format_error: 'Type de fichier invalide. Veuillez télécharger une image.',
  profile_page_logo_size_error:
    'La taille du fichier dépasse 1 Mo. Veuillez télécharger une image plus petite.',
  profile_page_version_info: 'Versions actuelles :',

  logo_error_message: "Impossible de charger le logo de l'entreprise",

  profile_page_country: 'Pays',
  profile_page_timezone: 'Fuseau horaire',
  profile_page_address: 'Adresse',
  profile_page_city: 'Ville',
  profile_page_municipality: 'Municipalité',
  profile_page_area: 'Région',
  profile_page_zip_code: 'Code postal',
  profile_page_vat_number: 'Numéro de TVA',
  profile_page_edit: 'Modifier le compte',
  profile_page_update_account_success: 'Compte mis à jour avec succès',
  profile_page_license_info: 'Informations sur la licence',

  /* |===============|
   * | Utility Rates |
   * |===============|
   */

  util_rates: 'Tarifs des services publics',

  // table
  util_rates_name: 'Nom',
  util_rates_price: 'Prix',
  util_rates_currency: 'Devise',
  util_rates_unit: 'Unité',
  util_rates_private: 'Privé',
  util_rate_company: 'Entreprise',
  util_rate_created: 'Créé',
  util_rates_updated: 'Mis à jour',
  util_rates_actions: 'Actions',
  util_rates_tooltip_inspect: 'Inspecter',

  // modals
  util_rates_add_modal_header: 'Ajouter un tarif de services publics',
  util_rates_edit_modal_header: 'Modifier {{name}}',
  util_rates_delete_modal_header: 'Supprimer {{name}}',
  util_rates_delete_modal_body:
    'Cela supprimera définitivement le tarif de services publics sélectionné.',
  util_rates_confirm: 'Confirmer',
  util_rates_cancel: 'Annuler',
  util_rates_loading: 'Chargement...',
  util_rates_error: 'Erreur...',
  util_rates_error_name_exists: 'Le nom existe déjà',

  // errors
  util_rate_fetch_company_error: 'Impossible de charger les entreprises',
  util_rate_fetch_util_rates_error: 'Impossible de charger les tarifs des services publics',

  // footer
  footer_powered_by: 'Propulsé par',
  util_rates_name_required: 'Nom requis',
  util_rates_price_required: 'Prix requis',

  /*
   * |=======|
   * | Icons |
   * |=======|
   */
  icon_type: 'Type',
  icon_type_device: 'Dispositif',
  icon_type_sensor: 'Capteur',

  icon_scope: 'Portée',
  icon_scope_global: 'Global',
  icon_scope_company: 'Entreprise',
  icon_scope_device: 'Dispositif',
  icon_scope_sensor: 'Capteur',

  icon_input_device: 'Dispositif',
  icon_input_sensor: 'Capteur',
  icon_input_name: 'Nom',
  icon_input_company: 'Entreprise',

  icon_btn_add_icon: 'Ajouter une icône',
  icon_btn_upload_file: 'Télécharger un fichier',
  icon_btn_cancel: 'Annuler',
  icon_btn_save: 'Enregistrer',

  icon_table_name: 'Nom',
  icon_table_scope: 'Portée',
  icon_table_icon: 'Icône',
  icon_table_actions: 'Actions',

  // errors
  icon_error_update_icon: "Impossible de mettre à jour l'icône",
  icon_error_unsupported_file_type:
    'Type de fichier non pris en charge. Seuls JPEG, PNG et SVG sont autorisés.',
  icon_error_error_occured: 'Une erreur est survenue',
  icon_error_enter_name: 'Veuillez entrer un nom',
  icon_error_enter_icon: 'Télécharger une icône',
  icon_error_select_company: 'Sélectionner une entreprise',
  icon_error_select_device: 'Sélectionner un dispositif',
  icon_error_select_sensor: 'Sélectionner un capteur',

  /*
   * |=========================|
   * | Customer Billing Report |
   * |=========================|
   */
  customer_billing_report_account_id_table_header: 'ID de compte',
  customer_billing_report_description_table_header: 'Description',
  customer_billing_report_customer_name_table_header: 'Nom du client',
  customer_billing_report_street_table_header: 'Rue',
  customer_billing_report_quarter_table_header: 'Trimestre',
  customer_billing_report_last_reading_table_header: 'Dernière lecture',
  customer_billing_report_last_reading_date_table_header: 'Date de la dernière lecture',
  customer_billing_report_device_number_table_header: 'Numéro de dispositif',
  customer_billing_report_last_config_date_table_header: 'Date de dernière configuration',
  customer_billing_report_billing_group_table_header: 'Groupe de facturation',
  customer_billing_report_meter_serial_table_header: 'Numéro de série',
  customer_billing_report_diameter_table_header: 'Diamètre',
  customer_billing_report_meter_brand_table_header: 'Marque',
  customer_billing_report_meter_model_table_header: 'Modèle',
  customer_billing_report_default_error: 'Une erreur est survenue lors de la génération du rapport',
  customer_billing_report_contract_number_table_header: 'Numéro de contrat',
  customer_billing_report_device_name_table_header: 'Nom du dispositif',
  customer_billing_report_sensor_name_table_header: 'Nom du capteur',

  //license
  license_license_warning: 'Avertissement de licence',
  license_license_warning_per_reading_over_limit_singular: 'de type par lecture dépasse la limite',
  license_license_warning_expire_singular: "sur le point d'expirer",
  license_license_warning_per_reading_over_limit_plural: 'de type par lecture dépassent la limite',
  license_license_warning_expire_plural: "sur le point d'expirer",
  license_max_count_error: 'Le nombre maximal ne peut pas être de 0',

  license_header: 'Licence',
  license_table_company_name: "Nom de l'entreprise",
  license_table_license_type: 'Type de licence',
  license_table_max_count: 'Nombre maximal',
  license_table_valid_from: 'Valide à partir de',
  license_table_valid_to: "Valide jusqu'à",
  license_table_first_reading_date: 'Date de la première lecture',
  license_table_last_reading_date: 'Date de la dernière lecture',
  license_table_active: 'Actif',
  license_table_valid: 'Valide',
  license_table_yes: 'Oui',
  license_table_no: 'Non',
  license_table_actions: 'Actions',

  license_warning_access_hold: 'Accès au compte suspendu',
  license_warning_access_hold_description:
    "Votre accès à la plateforme a été temporairement suspendu en raison d'un paiement non résolu. Nous vous encourageons à contacter notre support ou à prendre contact avec votre représentant désigné pour vous aider à résoudre ce problème.",
  license_warning_no_valid_license: 'Aucune licence valide',
  license_warning_no_valid_license_description:
    "Nous n'avons pas pu trouver une licence valide associée à ce compte. Veuillez contacter votre représentant désigné ou notre support pour obtenir de l'aide.",
  license_warning_devices_count_limit_reached: 'Limite de dispositifs atteinte',
  license_warning_devices_count_limit_reached_description:
    "Vous avez atteint le nombre maximal de dispositifs que vous pouvez associer au compte selon la licence. Si vous souhaitez en ajouter davantage, veuillez contacter votre représentant désigné ou notre support pour obtenir de l'aide.",
  license_info_renew_button: 'Renouveler la licence',
  license_info_deactivate_button: 'Désactiver la licence',
  license_info_renew_modal_button: 'Renouveler',
  license_info_used_readings: 'Lectures utilisées',
  license_info_readings_left: 'Lectures restantes',
  license_renew_license_button: 'Renouveler',
  license_deactivate_license_button: 'Désactiver',
  license_select_company_warning_message: 'Veuillez sélectionner une entreprise pour continuer',
  license_add_button: 'Ajouter une licence',
  license_add_success_message: 'Licence créée avec succès',
  license_add_error_message: 'Une erreur est survenue lors du processus',
  license_available_licenses: 'Licences disponibles',
  license_max_devices: 'Dispositifs max',
  license_max_readings: 'Lectures max',
  license_max_sensors: 'Capteurs max',
  license_company: 'Entreprise',
  license_deactivate_warning: "Cela suspendra l'accès des utilisateurs à la plateforme",
  license_activate_warning: "Cela rétablira l'accès des utilisateurs à la plateforme",
  license_activate_license_button: 'Activer',
  license_available_licenses: 'Licences disponibles',
  license_add_license: 'Ajouter une licence',
  license_status_active: 'Actif',
  license_status_inactive: 'Inactif',
  license_info_activate_button: 'Activer la licence',
  license_info_used_devices: 'Dispositifs utilisés',
  license_info_devices_left: 'Dispositifs restants',
  license_error_page_access_restricted: 'Accès restreint',
  license_valid_license_existing: 'Vous avez déjà une licence valide de ce type !',
  license_conflicting_license_date: 'Une licence valide existe pour la même période',

  //Password
  change_password: 'Changer de mot de passe',
  current_password: 'Mot de passe actuel',
  new_password: 'Nouveau mot de passe',
  confirm_new_password: 'Confirmer le nouveau mot de passe',
  customer_info_saved: 'Informations du client enregistrées',

  /*
   * |=========================|
   * |     Reset Password      |
   * |=========================|
   */
  reset_password_check_email_for_reset_link:
    'Vérifiez votre e-mail pour le lien de réinitialisation du mot de passe',
  reset_password_default_error_message:
    'Une erreur est survenue lors de la tentative de réinitialisation de votre mot de passe',
  reset_password_change_password_label: 'Changer de mot de passe',
  reset_password_send_btn: 'Envoyer',

  /*
   * |==============================|
   * |  Sensors and Network Config  |
   * |==============================|
   */

  //headers
  sensors_and_network_config_network_config: 'Configuration réseau de l’appareil',
  sensors_and_network_config_device_details: 'Détails de l’appareil',
  sensors_and_network_config_add_sensor: 'Ajouter un capteur',

  //messages
  sensors_and_network_save_success: 'La configuration a été enregistrée avec succès.',
  sensors_and_network_save_error:
    'Un problème est survenu lors de l’enregistrement de la configuration.',
  sensors_and_network_formula_validation_default_error: 'La validation de la formule a échoué.',
  sensors_and_network_config_required_field_error: 'Ce champ est obligatoire',

  //labels
  sensors_and_network_config_sensor_index: 'Index du capteur',

  //buttons
  sensors_and_network_config_reset_btn: 'Réinitialiser',

  //tooltips
  config_tooltip_select_sensor_icon: 'Sélectionner l’icône du capteur',
  config_tooltip_sensor_name: 'Nom du capteur',
  config_tooltip_enable_disable_sensor: 'Activer/Désactiver le capteur',
  config_tooltip_reset_sensor: 'Réinitialiser les nouvelles modifications pour ce capteur',
  config_tooltip_reset_all_fields:
    'Réinitialiser tous les champs aux dernières valeurs enregistrées',
  config_tooltip_delete_sensor: 'Supprimer le capteur',
  config_tooltip_sensor_description: 'Description du capteur',
  config_tooltip_sensor_model: 'Modèle du capteur',
  config_tooltip_sensor_serial_number: 'Numéro de série du capteur',
  config_tooltip_sensor_manufacturer: 'Fabricant du capteur',
  config_tooltip_sensor_useInSensorsAggregation:
    "Indique si les données doivent être utilisées par l'agrégateur automatique de données de Thingslog.",
  config_tooltip_sensor_visibility:
    'Détermine si le capteur doit être visible dans le portail de solutions de Thingslog.',
  config_tooltip_sensor_scale: 'La précision après la virgule pour tous les calculs de formule.',
  config_tooltip_port_formula: 'Utilisé pour la conversion des valeurs ADC brutes en mA ou V',
  config_tooltip_sensor_formula:
    'Utilisé pour la conversion de X en mA ou V en valeur mesurée, par exemple niveau, pression, température et autres.',
  config_tooltip_sensor_pulseRate: "Combien d'unités représente une impulsion",
  config_tooltip_sensor_offset:
    'Un nombre (+ ou -) qui sera ajouté aux relevés envoyés par l’appareil.',
  config_tooltip_sensor_flow_units: 'Unités du débit - par exemple, m3/h ou kW/h',
  config_tooltip_sensor_flow_time_units:
    'La période de temps utilisée pour le calcul de l’unité - par exemple, secondes',
  config_tooltip_sensor_flow_conversion_coefficient:
    'Combien d’unités de temps sont nécessaires pour obtenir l’unité à partir des relevés bruts',

  config_tooltip_sensor_diameter: 'Diamètre du tuyau',
  config_tooltip_sensor_q3: 'Coefficient Q3 du compteur d’eau',
  config_tooltip_sensor_r: 'Précision du compteur d’eau',

  /*
   * |==============================|
   * |     Documentation Page       |
   * |==============================|
   */

  documentation_title: 'Documentation Thingslog',
  documentation_platform: 'Documentation de la plateforme',
  documentation_data_logger: 'Documentation du data logger',
  documentation_communication_protocol: 'Documentation sur les protocoles de communication',
  documentation_sign_in_main_menu: 'Connexion et menu principal',
  documentation_account_management: 'Gestion des comptes',
  documentation_user_management: 'Gestion des utilisateurs',
  documentation_data_loggers_configuration: 'Configuration des data logger',
  documentation_alarms_value_range_events: 'Gestion des alarmes et des événements',
  documentation_modbus_slave_devices: 'Gestion des dispositifs esclaves Modbus',
  documentation_tasmota_devices:
    'Configuration des dispositifs Tasmota pour fonctionner avec la plateforme ThingsLog',
  documentation_modbus_setup: 'Configuration des dispositifs esclaves Modbus',
  documentation_licenses: 'Gestion des licences',
  documentation_old_user_manual: 'Ancien manuel utilisateur plateforme Thingslog',
  documentation_data_logger_manual: "Manuel de l'utilisateur du data logger Thingslog",
  documentation_data_logger_command_guide: 'Guide des commandes en ligne du data logger Thingslog',
  documentation_lora_protocol: 'Protocole de communication Lora',
  documentation_mqtt_protocol: 'Protocole de communication MQTT',
  documentation_view_pdf: 'Voir PDF',

  /*
   * |=====================|
   * | Device Nodes Page  |
   * |=====================|
   */

  device_nodes_table_no_links_found: 'Aucun lien de nœud de dispositif trouvé',
  device_nodes_table_error: 'Échec de récupération des liens de nœuds de dispositifs',
  device_nodes_table_source_device_number: 'Numéro de Dispositif Source',
  device_nodes_table_source_sensor_index: 'Index de Capteur Source',
  device_nodes_table_target_device_number: 'Numéro de Dispositif Cible',
  device_nodes_table_target_sensor_index: 'Index de Capteur Cible',
  device_nodes_table_is_active: 'Est Actif',

  device_nodes_create_link: 'Créer un Lien',
  device_nodes_source_device_required: 'Le dispositif source est requis',
  device_nodes_source_device: 'Dispositif Source',
  device_nodes_sensor_index_required: "L'index du capteur est requis",
  device_nodes_sensor_index_min: "L'index du capteur doit être au moins 0",
  device_nodes_source_sensor_index: 'Index de Capteur Source',
  device_nodes_target_device_required: 'Le dispositif cible est requis',
  device_nodes_target_device: 'Dispositif Cible',
  device_nodes_target_sensor_index: 'Index de Capteur Cible',
  device_nodes_is_active: 'Est Actif',
  device_nodes_no_sensors_available: 'Aucun capteur disponible',
  device_nodes_create_link_button: 'Créer un Lien',
  device_nodes_same_device_error:
    'Le dispositif source et le dispositif cible ne peuvent pas être identiques',

  device_nodes_delete_links: 'Supprimer les liens',
  device_nodes_device_required: "L'appareil est requis",
  device_nodes_device: 'Appareil',
  device_nodes_role_required: 'Le rôle est requis',
  device_nodes_role: 'Rôle',
  device_nodes_role_source: 'Source',
  device_nodes_role_target: 'Cible',
  device_nodes_sensor_index: 'Capteur (Optionnel)',
  device_nodes_no_sensors_available: 'Aucun capteur disponible',
  device_nodes_sensor_optional: "Laisser vide pour supprimer tous les liens de l'appareil",
  device_nodes_delete_warning: 'Attention : Cette action ne peut pas être annulée !',
  device_nodes_delete_button: 'Supprimer les liens',
};
export default i18n_fr;
