import React, { FC } from 'react';
import {
  useForm,
  Controller,
  SubmitHandler,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
} from 'react-hook-form';
import {
  Device,
  DeviceNodeLinkDto,
  DeviceNodeDto,
  PortsConfig,
  Port,
} from '@thingslog/repositories';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { deviceConfigQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';

interface DeviceOption {
  label: string;
  value: string;
}

interface SensorOption {
  label: string;
  value: number;
}

interface DeviceNodeLinkFormData {
  sourceDeviceNumber: string;
  sourceSensorIndex: number;
  targetDeviceNumber: string;
  targetSensorIndex: number;
  isActive: boolean;
}

interface DeviceNodeLinkCreateModalProps {
  devices: Device[];
  onCreateLink: (linkData: DeviceNodeLinkDto) => void;
  portsConfigs: Record<string, PortsConfig> | null;
}

const DeviceNodeLinkCreateModal: FC<DeviceNodeLinkCreateModalProps> = ({
  devices,
  onCreateLink,
  portsConfigs,
}: DeviceNodeLinkCreateModalProps) => {
  const { t } = useTranslation();

  // Create device options for dropdowns
  const deviceOptions: DeviceOption[] = React.useMemo(() => {
    return devices.map((device: Device) => ({
      label: `${device.name} (${device.number})`,
      value: device.number,
    }));
  }, [devices]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<DeviceNodeLinkFormData>({
    defaultValues: {
      sourceDeviceNumber: '',
      sourceSensorIndex: 0,
      targetDeviceNumber: '',
      targetSensorIndex: 0,
      isActive: true,
    },
    mode: 'onChange',
  });

  const watchSourceDevice = watch('sourceDeviceNumber');
  const sourceDeviceConfig = portsConfigs?.[watchSourceDevice] ?? null;
  const watchTargetDevice = watch('targetDeviceNumber');
  const targetDeviceConfig = portsConfigs?.[watchTargetDevice] ?? null;

  const getSensorOptionsFromConfig = (config: PortsConfig | null): SensorOption[] => {
    if (!config) return [];

    return Object.entries(config)
      .filter(([_, portConfig]: [string, Port]) => portConfig.enabled !== false) // Only show enabled ports
      .map(([portIndex, portConfig]: [string, Port]) => ({
        label: `${portConfig.sensor.name} (${portIndex})`,
        value: parseInt(portIndex, 10),
      }));
  };

  const sourceSensorOptions = React.useMemo(
    () => getSensorOptionsFromConfig(sourceDeviceConfig),
    [sourceDeviceConfig]
  );

  const targetSensorOptions = React.useMemo(
    () => getSensorOptionsFromConfig(targetDeviceConfig),
    [targetDeviceConfig]
  );

  const handleFormSubmit: SubmitHandler<DeviceNodeLinkFormData> = (
    data: DeviceNodeLinkFormData
  ) => {
    const linkData: DeviceNodeLinkDto = {
      sourceDeviceNode: {
        deviceNumber: data.sourceDeviceNumber,
        sensorIndex: data.sourceSensorIndex,
      },
      targetDeviceNode: {
        deviceNumber: data.targetDeviceNumber,
        sensorIndex: data.targetSensorIndex,
      },
      isActive: data.isActive,
    };

    onCreateLink(linkData);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col mt-5 gap-5">
      <Controller
        name="sourceDeviceNumber"
        control={control}
        rules={{
          required:
            (t('device_nodes_source_device_required') as string) || 'Source device is required',
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceNodeLinkFormData, 'sourceDeviceNumber'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceNodeLinkFormData>;
        }): React.ReactElement => (
          <Autocomplete
            options={deviceOptions}
            value={
              deviceOptions.find((option: DeviceOption) => option.value === field.value) || null
            }
            onChange={(_: unknown, option: DeviceOption | null): void => {
              field.onChange(option ? option.value : '');
            }}
            renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
              <TextField
                {...params}
                size="small"
                label={t('device_nodes_source_device') || 'Source Device'}
                error={!!errors.sourceDeviceNumber}
                helperText={errors.sourceDeviceNumber?.message}
                required
              />
            )}
          />
        )}
      />

      <Controller
        name="sourceSensorIndex"
        control={control}
        rules={{
          required:
            (t('device_nodes_sensor_index_required') as string) || 'Sensor index is required',
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceNodeLinkFormData, 'sourceSensorIndex'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceNodeLinkFormData>;
        }): React.ReactElement => (
          <FormControl
            fullWidth
            size="small"
            disabled={!watchSourceDevice || !sourceSensorOptions.length}
          >
            <Autocomplete
              options={sourceSensorOptions}
              value={
                sourceSensorOptions.find((option: SensorOption) => option.value === field.value) ||
                null
              }
              onChange={(_: unknown, option: SensorOption | null): void => {
                field.onChange(option ? option.value : 0);
              }}
              renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
                <TextField
                  {...params}
                  size="small"
                  label={t('device_nodes_source_sensor_index') || 'Source Sensor'}
                  error={!!errors.sourceSensorIndex}
                  helperText={
                    errors.sourceSensorIndex?.message ||
                    (!sourceSensorOptions.length && watchSourceDevice
                      ? t('device_nodes_no_sensors_available') || 'No sensors available'
                      : '')
                  }
                  required
                />
              )}
            />
          </FormControl>
        )}
      />

      <Controller
        name="targetDeviceNumber"
        control={control}
        rules={{
          required:
            (t('device_nodes_target_device_required') as string) || 'Target device is required',
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceNodeLinkFormData, 'targetDeviceNumber'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceNodeLinkFormData>;
        }): React.ReactElement => (
          <Autocomplete
            options={deviceOptions.filter(
              (option: DeviceOption) => option.value !== watchSourceDevice
            )}
            value={
              deviceOptions.find((option: DeviceOption) => option.value === field.value) || null
            }
            onChange={(_: unknown, option: DeviceOption | null): void => {
              field.onChange(option ? option.value : '');
            }}
            renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
              <TextField
                {...params}
                size="small"
                label={t('device_nodes_target_device') || 'Target Device'}
                error={!!errors.targetDeviceNumber}
                helperText={errors.targetDeviceNumber?.message}
                required
              />
            )}
          />
        )}
      />

      <Controller
        name="targetSensorIndex"
        control={control}
        rules={{
          required:
            (t('device_nodes_sensor_index_required') as string) || 'Sensor index is required',
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceNodeLinkFormData, 'targetSensorIndex'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceNodeLinkFormData>;
        }): React.ReactElement => (
          <FormControl
            fullWidth
            size="small"
            disabled={!watchTargetDevice || !targetSensorOptions.length}
          >
            <Autocomplete
              options={targetSensorOptions}
              value={
                targetSensorOptions.find((option: SensorOption) => option.value === field.value) ||
                null
              }
              onChange={(_: unknown, option: SensorOption | null): void => {
                field.onChange(option ? option.value : 0);
              }}
              renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
                <TextField
                  {...params}
                  size="small"
                  label={t('device_nodes_target_sensor_index') || 'Target Sensor'}
                  error={!!errors.targetSensorIndex}
                  helperText={
                    errors.targetSensorIndex?.message ||
                    (!targetSensorOptions.length && watchTargetDevice
                      ? t('device_nodes_no_sensors_available') || 'No sensors available'
                      : '')
                  }
                  required
                />
              )}
            />
          </FormControl>
        )}
      />

      <Controller
        name="isActive"
        control={control}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceNodeLinkFormData, 'isActive'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceNodeLinkFormData>;
        }): React.ReactElement => (
          <FormControlLabel
            control={
              <Switch
                checked={field.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  field.onChange(e.target.checked)
                }
              />
            }
            label={t('device_nodes_is_active') || 'Active'}
          />
        )}
      />

      <Button
        type="submit"
        disabled={!isValid || watchSourceDevice === watchTargetDevice}
        variant="contained"
      >
        {t('device_nodes_create_link_button') || 'Create Link'}
      </Button>

      {watchSourceDevice === watchTargetDevice && watchSourceDevice && watchTargetDevice && (
        <div className="text-red-500 text-sm">
          {t('device_nodes_same_device_error') || 'Source and target cannot be the same device'}
        </div>
      )}
    </form>
  );
};

export default DeviceNodeLinkCreateModal;
