import { DeviceNodeLinkDto } from '@thingslog/repositories';
import {
  DataGrid,
  DataGridHeader,
  DataGridProps,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from '@thingslog/ui-components';
import React, { ReactNode, useEffect, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';

const DeviceNodesTable: FC<DeviceNodesTableProps> = ({
  deviceNodeLinks,
  ...props
}: DeviceNodesTableProps) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<DeviceNodeLinkRow[]>([]);

  useEffect(() => {
    let rows: DeviceNodeLinkRow[] = [];

    deviceNodeLinks.forEach((link: DeviceNodeLinkDto, index: number) => {
      rows.push({
        id: index.toString(),
        sourceDeviceNumber: link.sourceDeviceNode.deviceNumber,
        sourceSensorIndex: link.sourceDeviceNode.sensorIndex,
        targetDeviceNumber: link.targetDeviceNode.deviceNumber,
        targetSensorIndex: link.targetDeviceNode.sensorIndex,
        isActive: link.isActive,
      });
    });
    setRows(rows);
  }, [deviceNodeLinks]);

  return (
    <DataGrid
      rows={rows}
      pageSize={25}
      columns={[
        {
          field: 'sourceDeviceNumber',
          headerName: t('device_nodes_table_source_device_number') || 'Source Device Number',
          flex: 1,
          minWidth: 150,
          sortable: true,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
        },
        {
          field: 'sourceSensorIndex',
          headerName: t('device_nodes_table_source_sensor_index') || 'Source Sensor Index',
          flex: 1,
          minWidth: 150,
          sortable: true,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
        },
        {
          field: 'targetDeviceNumber',
          headerName: t('device_nodes_table_target_device_number') || 'Target Device Number',
          flex: 1,
          minWidth: 150,
          sortable: true,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
        },
        {
          field: 'targetSensorIndex',
          headerName: t('device_nodes_table_target_sensor_index') || 'Target Sensor Index',
          flex: 1,
          minWidth: 150,
          sortable: true,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
        },
        {
          field: 'isActive',
          headerName: t('device_nodes_table_is_active') || 'Active',
          flex: 1,
          minWidth: 100,
          sortable: true,
          renderHeader: (params: GridColumnHeaderParams): ReactNode => {
            return <DataGridHeader headerName={params.colDef.headerName!} />;
          },
          renderCell: (params: GridRenderCellParams): ReactNode => {
            return params.value ? t('yes') || 'Yes' : t('no') || 'No';
          },
        },
      ]}
      {...props}
    />
  );
};

interface DeviceNodesTableProps extends Omit<DataGridProps, 'columns' | 'rows'> {
  deviceNodeLinks: DeviceNodeLinkDto[];
}

export interface DeviceNodeLinkRow {
  id: string;
  sourceDeviceNumber: string;
  sourceSensorIndex: number;
  targetDeviceNumber: string;
  targetSensorIndex: number;
  isActive: boolean;
}

export default DeviceNodesTable;
