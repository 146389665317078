export enum PageIds {
  LINK_PUMP = 'link_pump',
  LINK_RELAY = 'link_relay',
  LINK_HOME_PAGE = 'link_home_page',
  LINK_DASHBOARD = 'link_dashboard',
  LINK_PROFILE = 'link_profile',
  LINK_DEVICES = 'link_devices',
  LINK_DEVICE_GROUPS = 'link_device_groups',
  LINK_NEW_GRAPH_DEVICE = 'link_new_graph_device',
  LINK_GRAPH_DEVICE = 'link_graph_device',
  LINK_READINGS_DEVICE = 'link_readings_device',
  LINK_COMMANDS_DEVICE = 'link_commands_device',
  LINK_COPY_CONFIG_DEVICE = 'link_copy_config_device',
  LINK_DEVICE_MANAGER = 'link_device_manager',
  LINK_LINK_DEVICE = 'link_link_device',
  LINK_DEVICE_ACCOUNT_LOOKUP = 'link_device_account_lookup',
  LINK_CONFIG_DEVICE = 'link_config_device',
  LINK_SENSOR_CONFIG_DEVICE = 'link_sensor_config_device',
  LINK_INITIAL_CONFIG_DEVICE = 'link_initial_config_device',
  LINK_STATISTICS_DEVICE = 'link_statistics_device',
  LINK_MULTI_DEVICE_COUNTERS_STATISTICS = 'link_multi_device_counters_statistics',
  LINK_MULTI_DEVICE_COUNTERS_STATISTICS_FLOW = 'link_multi_device_counters_statistics_flow',
  LINK_MULTI_DEVICE_COUNTERS_STATISTICS_Q_FLOW = 'link_multi_device_counters_statistics_q_flow',
  LINK_MULTI_DEVICE_COUNTERS_STATISTICS_BY_TIME = 'link_multi_device_counters_statistics_by_time',
  LINK_MULTI_DEVICE_FLOW_GRAPH = 'link_multi_device_flow_graph',
  LINK_CUSTOMER_DEVICE = 'link_customer_device',
  LINK_METER_INFO_DEVICE = 'link_meter_info_device',
  LINK_EXPORT_DEVICE = 'link_export_device',
  LINK_EXPORT = 'link_export',
  LINK_SIGNAL_DEVICE = 'link_signal_device',
  LINK_BATTERY_DEVICE = 'link_battery_device',
  LINK_FLOW_DEVICE = 'link_flow_device',
  LINK_DEVICE_COUNTERS_CUMULATIVE_GRAPH = 'link_device_counters_cumulative_graph',
  LINK_TRANSMISSIONS = 'link_transmissions',
  LINK_INVENTORY = 'link_inventory',
  LINK_ALARMS_DEVICE = 'link_alarms_device',
  LINK_ALARMS = 'link_alarms',
  LINK_NEW_GRAPH = 'link_new_graph',
  LINK_ALARM_CONFIG_DEVICE = 'link_alarm_config_device',
  LINK_FLOW_GENERATOR_DEVICE = 'link_flow_generator_device',
  LINK_MAP = 'link_map',
  LINK_MAP_DEVICE = 'link_map_device',
  LINK_BATTERY = 'link_battery',
  LINK_SIGNAL = 'link_signal',
  LINK_CHANGE_PASSWORD = 'link_change_password',
  LINK_SHOW_USERS = 'link_show_users',
  LINK_ACCOUNTS = 'link_accounts',
  LINK_ACCOUNTS_MANAGER = 'link_accounts_manager',
  LINK_LANGUAGE = 'link_language',
  LINK_REPORTS = 'link_reports',
  LINK_LICENSE_MANAGER = 'link_license_manager',
  LINK_DEVICE_PREDICTION_DEVICE = 'link_device_prediction_device',
  LINK_VALUE_RANGES_DEVICE = 'link_value_ranges_device',
  LINK_CREATE_USER = 'link_create_user',
  LINK_UTILITY_RATE = 'link_utility_rate',
  LINK_BILLING_REPORT = 'link_billing_report',
  LINK_ICONS = 'link_icons',
  LINK_LICENSES = 'link_licenses',
  LINK_CUSTOMER_BILLING_REPORT = 'link_customer_billing_report',
  LINK_SENSORS_AND_NETWORK_CONFIG = 'link_sensors_and_network_config',
  SUBMENU_DEVICES = 'submenu_devices',
  SUBMENU_ACCOUNT = 'submenu_account',
  SUBMENU_DEVICE_REPORTS = 'submenu_device_reports',
  SUBMENU_SETTINGS = 'submenu_settings',
  RESTRICTED_ACCESS = 'restricted_access',
  LINK_DEVICE_NODES = 'link_device_nodes',
}
