import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';

import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { HelperTextInputField } from '@thingslog/ui-components';
import { Tooltip } from '@mui/material';

const WaterMeterConfig: FC<WaterMeterConfigProps> = ({ index }: WaterMeterConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ register }: UseFormReturn<InitialConfigFormFields>): ReactElement => (
        <>
          <Tooltip arrow title={t('config_tooltip_sensor_diameter') ?? ''}>
            <HelperTextInputField
              {...register(`ports.${index}.sensor.diameter`)}
              label={t('sensor_config_diameter')}
              size="small"
            />
          </Tooltip>

          <Tooltip arrow title={t('config_tooltip_sensor_q3') ?? ''}>
            <HelperTextInputField
              {...register(`ports.${index}.sensor.q3`)}
              label="q3"
              size="small"
            />
          </Tooltip>

          <Tooltip arrow title={t('config_tooltip_sensor_r') ?? ''}>
            <HelperTextInputField {...register(`ports.${index}.sensor.r`)} label="r" size="small" />
          </Tooltip>
        </>
      )}
    </ConnectForm>
  );
};

interface WaterMeterConfigProps {
  index: number;
}

export default WaterMeterConfig;
