import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { QueryKeys } from '../enums/QueryKeys';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  DeviceNodeLinkDto,
  CurrentDeviceRole,
  MutationOptions,
  MutationResult,
  QueryOptions,
  QueryResult,
} from '@thingslog/repositories';

export interface DeviceNodeLinksQueryClient {
  getDeviceNodes: (companyId?: number) => Promise<DeviceNodeLinkDto[]>;
  useDeviceNodesData: (
    companyId?: number,
    options?: QueryOptions<DeviceNodeLinkDto[]>
  ) => QueryResult<DeviceNodeLinkDto[]>;

  getDeviceNode: (
    deviceNumber: string,
    sensorIndex: number,
    currentDeviceRole?: CurrentDeviceRole
  ) => Promise<DeviceNodeLinkDto[]>;
  useDeviceNodeData: (
    deviceNumber: string,
    sensorIndex: number,
    currentDeviceRole?: CurrentDeviceRole,
    options?: QueryOptions<DeviceNodeLinkDto[]>
  ) => QueryResult<DeviceNodeLinkDto[]>;

  createDeviceNodeLink: (deviceNodeLinkDto: DeviceNodeLinkDto) => Promise<DeviceNodeLinkDto>;
  useCreateDeviceNodeLink: (
    options?: MutationOptions<DeviceNodeLinkDto, DeviceNodeLinkDto>
  ) => MutationResult<DeviceNodeLinkDto, DeviceNodeLinkDto>;

  deleteAllDeviceNodesForDevice: (deviceNumber: string, role: CurrentDeviceRole) => Promise<void>;
  useDeleteAllDeviceNodesForDevice: (
    options?: MutationOptions<void, DeleteAllNodesParams>
  ) => MutationResult<void, DeleteAllNodesParams>;

  deleteDeviceNodesForDeviceSensor: (
    deviceNumber: string,
    sensorIndex: number,
    role: CurrentDeviceRole
  ) => Promise<void>;
  useDeleteDeviceNodesForDeviceSensor: (
    options?: MutationOptions<void, DeleteNodeSensorParams>
  ) => MutationResult<void, DeleteNodeSensorParams>;
}

export function createDeviceNodeLinksQueryClient(axios: AxiosInstance): DeviceNodeLinksQueryClient {
  return new DeviceNodeLinksImp(axios);
}

class DeviceNodeLinksImp implements DeviceNodeLinksQueryClient {
  public constructor(private axios: AxiosInstance) {}

  public getDeviceNodes = async (companyId?: number): Promise<DeviceNodeLinkDto[]> => {
    return await this.axios
      .get(`/api/device-node-links`, { params: { companyId } })
      .then((response: AxiosResponse<DeviceNodeLinkDto[]>) => response.data);
  };

  public useDeviceNodesData = (
    companyId?: number,
    options?: QueryOptions<DeviceNodeLinkDto[]>
  ): QueryResult<DeviceNodeLinkDto[]> => {
    return useQuery<DeviceNodeLinkDto[], AxiosError>(
      [QueryKeys.UseDeviceNodeLinks, companyId],
      () => this.getDeviceNodes(companyId),
      options
    );
  };

  public getDeviceNode = async (
    deviceNumber: string,
    sensorIndex: number,
    currentDeviceRole?: CurrentDeviceRole
  ): Promise<DeviceNodeLinkDto[]> => {
    return await this.axios
      .get(`/api/device-node-links/devices/${deviceNumber}/sensors/${sensorIndex}`, {
        params: { useCurrentDeviceAs: currentDeviceRole },
      })
      .then((response: AxiosResponse<DeviceNodeLinkDto[]>) => response.data);
  };

  public useDeviceNodeData = (
    deviceNumber: string,
    sensorIndex: number,
    currentDeviceRole?: CurrentDeviceRole,
    options?: QueryOptions<DeviceNodeLinkDto[]>
  ): QueryResult<DeviceNodeLinkDto[]> => {
    return useQuery<DeviceNodeLinkDto[], AxiosError>(
      [QueryKeys.UseDeviceNodeLink, deviceNumber, sensorIndex, currentDeviceRole],
      () => this.getDeviceNode(deviceNumber, sensorIndex, currentDeviceRole),
      options
    );
  };

  public createDeviceNodeLink = async (
    deviceNodeLinkDto: DeviceNodeLinkDto
  ): Promise<DeviceNodeLinkDto> => {
    return await this.axios
      .post(`/api/device-node-links`, deviceNodeLinkDto)
      .then((response: AxiosResponse<DeviceNodeLinkDto>) => response.data);
  };

  public useCreateDeviceNodeLink = (
    options?: MutationOptions<DeviceNodeLinkDto, DeviceNodeLinkDto>
  ): MutationResult<DeviceNodeLinkDto, DeviceNodeLinkDto> => {
    return useMutation<DeviceNodeLinkDto, AxiosError, DeviceNodeLinkDto>(
      [QueryKeys.CreateDeviceNodeLink],
      (params: DeviceNodeLinkDto) => this.createDeviceNodeLink(params),
      options
    );
  };

  public deleteAllDeviceNodesForDevice = async (
    deviceNumber: string,
    role: CurrentDeviceRole
  ): Promise<void> => {
    return await this.axios
      .delete(`/api/device-node-links/devices/${deviceNumber}`, {
        params: { useCurrentDeviceAs: role },
      })
      .then((response: AxiosResponse<void>) => response.data);
  };

  public useDeleteAllDeviceNodesForDevice = (
    options?: MutationOptions<void, DeleteAllNodesParams>
  ): MutationResult<void, DeleteAllNodesParams> => {
    return useMutation<void, AxiosError, DeleteAllNodesParams>(
      [QueryKeys.DeleteAllDeviceNodeLinks],
      (params: DeleteAllNodesParams) =>
        this.deleteAllDeviceNodesForDevice(params.deviceNumber, params.role),
      options
    );
  };

  public getDeviceNodesForDeviceSensor = async (
    deviceNumber: string,
    sensorIndex: number,
    role?: CurrentDeviceRole
  ): Promise<DeviceNodeLinkDto[]> => {
    return await this.axios
      .get(`/api/device-node-links/devices/${deviceNumber}/sensors/${sensorIndex}`, {
        params: { useCurrentDeviceAs: role },
      })
      .then((response: AxiosResponse<DeviceNodeLinkDto[]>) => response.data);
  };

  public useDeviceNodesForDeviceSensor = (
    deviceNumber: string,
    sensorIndex: number,
    role?: CurrentDeviceRole,
    options?: QueryOptions<DeviceNodeLinkDto[]>
  ): QueryResult<DeviceNodeLinkDto[]> => {
    return useQuery<DeviceNodeLinkDto[], AxiosError>(
      [QueryKeys.UseDeviceNodeSensorLinks, deviceNumber, sensorIndex, role],
      () => this.getDeviceNodesForDeviceSensor(deviceNumber, sensorIndex, role),
      options
    );
  };

  public deleteDeviceNodesForDeviceSensor = async (
    deviceNumber: string,
    sensorIndex: number,
    role: CurrentDeviceRole
  ): Promise<void> => {
    return await this.axios
      .delete(`/api/device-node-links/devices/${deviceNumber}/sensors/${sensorIndex}`, {
        params: { useCurrentDeviceAs: role },
      })
      .then((response: AxiosResponse<void>) => response.data);
  };

  public useDeleteDeviceNodesForDeviceSensor = (
    options?: MutationOptions<void, DeleteNodeSensorParams>
  ): MutationResult<void, DeleteNodeSensorParams> => {
    return useMutation<void, AxiosError, DeleteNodeSensorParams>(
      [QueryKeys.DeleteDeviceNodeSensorLinks],
      (params: DeleteNodeSensorParams) =>
        this.deleteDeviceNodesForDeviceSensor(params.deviceNumber, params.sensorIndex, params.role),
      options
    );
  };
}

interface DeleteAllNodesParams {
  deviceNumber: string;
  role: CurrentDeviceRole;
}

interface DeleteNodeSensorParams {
  deviceNumber: string;
  sensorIndex: number;
  role: CurrentDeviceRole;
}
