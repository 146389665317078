export enum GaEventAction {
  /* DEVICE MENU ACTIONS */
  MENU_ALL_DEVICES_PAGE_CLICK = 'Menu All Devices Page Click',
  MENU_READINGS_PAGE_CLICK = 'Menu Readings Page Click',
  MENU_NEW_GRAPH_PAGE_CLICK = 'Menu New Graph Page Click',
  MENU_FLOW_GRAPH_PAGE_CLICK = 'Menu Flow Graph Page Click',
  MENU_GRAPH_PAGE_CLICK = 'Menu Graph Page Click',
  MENU_ALARM_CONFIG_PAGE_CLICK = 'Menu Alarm Config Page Click',
  MENU_DEVICE_MAP_PAGE_CLICK = 'Menu Device Map Page Click',
  MENU_STATISTICS_PAGE_CLICK = 'Menu Statistics Page Click',
  MENU_EXPORT_PAGE_CLICK = 'Menu Export Page Click',
  MENU_CUSTOMER_PAGE_CLICK = 'Menu Customer Page Click',
  MENU_CONFIG_PAGE_CLICK = 'Menu Config Page Click',
  MENU_INITIAL_CONFIG_PAGE_CLICK = 'Menu Initial Config Page Click',
  MENU_SENSOR_CONFIG_PAGE_CLICK = 'Menu Sensor Config Page Click',
  MENU_DEVICE_COMMANDS_PAGE_CLICK = 'Menu Device Commands Page Click',
  MENU_DEVICE_COPY_CONFIG_PAGE_CLICK = 'Menu Device Copy Config Page Click',
  MENU_DEVICE_SIGNAL_PAGE_CLICK = 'Menu Device Signal Page Click',
  MENU_DEVICE_BATTERY_PAGE_CLICK = 'Menu Device Battery Page Click',
  MENU_ALARMS_PAGE_CLICK = 'Menu Alarms Page Click',
  MENU_DEVICE_PREDICTION_PAGE_CLICK = 'Menu Device Prediction Page Click',
  MENU_VALUE_RANGE_PAGE_CLICK = 'Menu Value Range Page Click',
  MENU_CUSTOMER_BILLING_REPORT_PAGE_CLICK = 'Menu Customer Billing Report Page Click',
  MENU_SENSORS_AND_NETWORK_CONFIG_PAGE_CLICK = 'Menu Sensors And Network Config Page Click',

  /* DEFAULT MENU ACTIONS */
  MENU_SELECTED_DEVICE_PAGE_CLICK = 'Menu Selected Device Page Click',
  MENU_DEVICES_PAGE_CLICK = 'Menu Devices Page Click',
  MENU_MAP_PAGE_CLICK = 'Menu Map Page Click',
  MENU_TRANSMISSIONS_PAGE_CLICK = 'Menu Transmissions Page Click',
  MENU_INVENTORY_PAGE_CLICK = 'Menu Inventory Page Click',
  MENU_LINK_DEVICE_PAGE_CLICK = 'Menu Link Device Page Click',
  MENU_USERS_PAGE_CLICK = 'Menu Users Page Click',
  MENU_CREATE_USER_PAGE_CLICK = 'Menu Create User Page Click',
  MENU_ICON_PAGE_CLICK = 'Menu Icon Page Click',
  MENU_DEVICE_MANAGER_PAGE_CLICK = 'Menu Device Manager Page Click',
  MENU_ACCOUNTS_PAGE_CLICK = 'Menu Accounts Page Click',
  MENU_MANAGE_ACCOUNTS_PAGE_CLICK = 'Menu Manage Accounts Page Click',
  MENU_MANAGE_ICONS_PAGE_CLICK = 'Menu Manage Icons Page Click',
  MENU_MANAGE_LICENSES_PAGE_CLICK = 'Menu Licenses Page Click',
  MENU_DEVICES_BATTERY_PAGE_CLICK = 'Menu Devices Battery Page Click',
  MENU_DEVICES_SIGNAL_PAGE_CLICK = 'Menu Devices Signal Page Click',
  MENU_DASHBOARD_PAGE_CLICK = 'Menu Dashboard Page Click',
  MENU_REPORTS_PAGE_CLICK = 'Menu Reports Page Click',
  MENU_ALL_ALARMS_PAGE_CLICK = 'Menu All Alarms Page Click',
  MENU_DEVICE_GROUP_PAGE_CLICK = 'Menu Device Group Page Click',
  MENU_DEVICE_NODES_PAGE_CLICK = 'Menu Device Nodes Page Click',
  MENU_BILLING_REPORT_PAGE_CLICK = 'Menu Billing Report Page Click',
  MENU_UTILITY_RATE_PAGE_CLICK = 'Menu Utility Rate Page Click',

  /* PAGE ACTIONS */
  DEVICES_PAGE_DEVICE_NUMBER_CLICK = 'Device Number Click',
  INVENTORY_PAGE_DEVICE_NUMBER_CLICK = 'Device Number Click',
  DEVICES_SIGNAL_PAGE_DEVICE_NUMBER_CLICK = 'Device Number Click',
  BATTERY_STATUS_PAGE_DEVICE_NUMBER_CLICK = 'Device Number Click',
  TRANSMISSIONS_PAGE_APPLY_BUTTON_CLICKED = 'Apply Button Clicked',
  LINK_DEVICE_PAGE_LINK_DEVICE = 'Link Device',
  LINK_DEVICE_PAGE_UNLINK_DEVICE = 'Unlink Device',
  DEVICE_MANAGER_CREATE_DEVICE = 'Create Device',
  DEVICE_MANAGER_DELETE_DEVICE = 'Delete Device',
  CREATE_USER_SIGN_IN = 'Sign in',
  REPORT_PAGE_EXPORT = 'Export Report',
  REPORT_PAGE_FORMAT = 'Export Format',
  ALL_ALARMS_PAGE_APPLY = 'Apply Click',
  READING_PAGE_APPLY = 'Apply Click',
  FLOW_PAGE_APPLY = 'Apply Click',
  GRAPH_PAGE_APPLY = 'Apply Click',
  ALARM_CONFIG_SAVE = 'Save Button',
  EXPORT_PAGE_APPLY = 'Apply Click',
  CUSTOMER_PAGE_SAVE = 'Save Click',
  CONFIG_START_SETUP = 'Start Setup Click',
  INITIAL_CONFIG_SAVE = 'Save Click',
  SENSOR_CONFIG_SAVE = 'Save Click',
  SIGNAL_PAGE_APPLY = 'Apply Click',
  BATTERY_PAGE_APPLY = 'Apply Click',
  ALARMS_PAGE_APPLY = 'Apply Click',

  /* PROFILE PAGE ACTIONS */
  CHANGE_LANGUAGE_SAVE = 'Language Change Save Click',
  CHANGE_PASSWORD_SAVE = 'Password Change Save Click',
}
