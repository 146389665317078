import React, { Component } from 'react';
// import logo from './logo.png';
import './App.css';
import Graph from './pages/graph/Graph';
import Pump from './pages/relaySwitchDemo/Pump';
import Relay from './pages/relaySwitchDemo/Relay';
import Profile from './Profile';
import ProfileV2 from './pages/profile/ProfileV2';
import DeviceConfig from './DeviceConfig';
import SensorsConfig from './SensorsConfig';
import TransmissionsPage from './pages/transmissions/TransmissionsPage';
import AlarmsExport from './pages/alarms/AlarmsExport';
import AlarmConfig from './AlarmConfig';
import FlowGenerator from './FlowGenerator';
import DeviceInitialConfig from './DeviceInitialConfig';
import CustomerInfo from './CustomerInfo';
import MeterInfo from './MeterInfo';
import DeviceManager from './DeviceManager';
import DeviceCountersStatistics from './DeviceCountersStatistics';
import LicensePage from './pages/license/License';
import Dashboard from './Dashboard';
import MultiDeviceCounterStatisticsFlow from './MultiDeviceCounterStatisticsFlow';
import MultiDeviceCounterStatisticsQFlow from './MultiDeviceCounterStatisticsQFlow';
import MultiDeviceCounterStatisticsByTime from './MultiDeviceCounterStatisticsByTime';
import MultiDeviceCounterStatistics from './MultiDeviceCounterStatistics';
import MultiDeviceFlowsGraph from './MultiDeviceFlowsGraph';
import DeviceCountersGraph from './DeviceCountersGraph';
import EventValueRangePage from './pages/eventValueRage/EventValueRange';
import DeviceCountersCumulativeGraph from './DeviceCountersCumulativeGraph';
// import DeviceCountersHistogram from './DeviceCountersHistogram';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
// https://github.com/gillisd/react-router-v4-redux-auth/blob/master/client/src/index.js
//http://www.svlada.com/jwt-token-authentication-with-spring-boot/
import Signin from './pages/signup/Signin';
import Signout from './components/auth/signout';
import ConfirmRegistration from './components/auth/confirm_registration';
import JssProvider from 'react-jss/lib/JssProvider';

import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@mui/styles';
import ChangePassword from './PasswordChange';

import Language from './Language';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import { withTranslation } from 'react-i18next';
import CompanyManager from './CompanyManager';
import CompanyManagerV2 from './pages/companyManager/CompanyManagerV2';
import Users from './pages/users/Users';
import LicenseManager from './LicenseManager';
import ResetPassword from './pages/signup/ResetPassword';
import SetPassword from './SetPassword';
import AccountsPage from './pages/accounts/AccountsPage';
import DeviceSearch from './pages/deviceSearch/DeviceSearch';
import DeviceGroups from './pages/device_groups/DeviceGroups';
import Commands from './pages/commands/CommandsWithContext';
import CopyConfigPage from './pages/copyConfig/CopyConfigPage';
import { cyan } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Statistics from './pages/statistics/Statistics';
import BasePage from './pages/base/BasePage';
import Location from './pages/location/Location';
import DevicePrediction from './pages/devicePrediction/DevicePrediction';
import { ReactQueryWrapper } from '@thingslog/queries';
import {
  ModalProvider,
  ToastProvider,
  MuiCoreLocale,
  DatePickerLocale,
  DataGridLocale,
} from '@thingslog/ui-components';
import CountersExport from './pages/countersExport/CountersExport';
import ConsumptionExport from './pages/consumptionExport/ConsumptionExport';
import DevicesPage from './pages/devices/DevicesPage';
import Inventory from './pages/inventory/Inventory';
import PrivateOutlet from './components/auth/privateOutlet';
import JwtValidator from './common/JwtValidator';
import LicenseValidator from './common/LicenseValidator';
import DevicesSignalV2 from './pages/signal/DevicesSignalPageV2';
import DeviceBatteryPageV2 from './pages/battery/DeviceBatteryPageV2';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import i18n from '../src/i18n';
import { PageIds } from './model/Navigation/PageIds';
import GraphV2 from './pages/graphV2/GraphV2';
import BillingReport from './pages/billingReport/BillingReport';
import UtilityRatePage from './pages/utilityRate/UtilityRate.page';
import { IconsPage } from './pages/icons/Icon.page';
import { AddIconPage } from './pages/icons/AddIcon.page';
import SettingsV2 from './pages/settings/SettingsV2';
import { setDefaultOptions } from 'date-fns';
import { bg, de, enGB, el, es, ru } from 'date-fns/locale';
import LicenseError from './pages/licenseError/LicenseError';
import LinkDevicesV2 from './pages/linkDevices/LinkDevicesV2';
import LinkDevices from './LinkDevices';
import CustomerBillingReport from './pages/customerBillingReport/CustomerBillingReport';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SensorsAndNetworkConfig from './pages/sensorsAndNetworkConfig/SensorsAndNetworkConfig';
import Documentation from './pages/documentation/Documentation';
import { DeviceNodesPage } from './pages/deviceNodes/DeviceNodes.page';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});
const jss = create(jssPreset());

const { recaptchaKeyV2, recaptchaKeyV3, recaptchaRequired, recaptchaV3 } = window['runConfig'];
export const global_env = {
  recaptcha: recaptchaV3 ? recaptchaKeyV3 : recaptchaKeyV2,
  recaptchaRequired: recaptchaRequired,
  recaptchaV3: recaptchaV3,
  version: process.env.REACT_APP_VERSION,
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.jwtValidator = new JwtValidator();
    this.locales = { bg: bg, de: de, en: enGB, gr: el, ru: ru, es: es };
  }
  componentDidMount() {
    this.props.i18n.changeLanguage(this.props.auth.language || 'en');
    if (this.props.auth.language) {
      setDefaultOptions({ locale: this.locales[this.props.auth.language] });
    }
    GoogleAnalyticsService.initGA();
  }

  isFeatureFlagOn = (flagName) => {
    return this.props.featureFlags.featureFlagsArray.includes(flagName);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.featureFlags.featureFlagsArray !== this.props.featureFlags.featureFlagsArray) {
      this.setState({});
    }

    if (prevProps.auth.language !== this.props.auth.language && this.props.auth.language) {
      this.props.i18n.changeLanguage(this.props.auth.language);
      setDefaultOptions({ locale: this.locales[this.props.auth.language] });
    }
  }
  render() {
    const theme = createTheme(
      {
        typography: {
          useNextVariants: true,
        },
        palette: {
          primary: { main: cyan[500] }, // Purple and green play nicely together.
          secondary: { main: cyan[500] }, // This is just green.A700 as hex.
        },
      },
      DataGridLocale[i18n.language],
      MuiCoreLocale[i18n.language],
      DatePickerLocale[i18n.language]
    );

    return (
      <GoogleReCaptchaProvider reCaptchaKey={global_env.recaptcha}>
        <ReactQueryWrapper>
          <JssProvider jss={jss} generateClassName={generateClassName}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <ModalProvider>
                  <ToastProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div className="App">
                        <Routes>
                          <Route
                            path="/app/confirm-registration"
                            element={<ConfirmRegistration />}
                          />
                          <Route path="/app/signin" element={<Signin />} />
                          <Route path="/app/signout" element={<Signout />} />
                          <Route path="/app/ResetPassword" element={<ResetPassword />} />
                          <Route
                            path="/app/SetPassword/:confirmationToken"
                            element={<SetPassword />}
                          />
                          <Route
                            path="/app/Pump"
                            element={<PrivateOutlet pageId={PageIds.LINK_PUMP} />}
                          >
                            <Route path="/app/Pump" element={<Pump />} />
                          </Route>
                          <Route
                            path="/app/Relay"
                            element={<PrivateOutlet pageId={PageIds.LINK_RELAY} />}
                          >
                            <Route path="/app/Relay" element={<Relay />} />
                          </Route>
                          <Route
                            path="/"
                            element={<PrivateOutlet pageId={PageIds.LINK_HOME_PAGE} />}
                          >
                            <Route path="/" exact={true} element={<BasePage />} />
                          </Route>
                          <Route
                            path="/app"
                            element={<PrivateOutlet pageId={PageIds.LINK_DASHBOARD} />}
                          >
                            <Route path="/app" exact={true} element={<Dashboard />} />
                          </Route>
                          <Route
                            path="/app/Profile"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_PROFILE}
                                isNonRestrictedAccess={true}
                              />
                            }
                          >
                            {this.isFeatureFlagOn('LICENSE_MANAGEMENT_FEATURE_FLAG') ? (
                              <Route path="/app/Profile" element={<ProfileV2 />} />
                            ) : (
                              <Route path="/app/Profile" element={<Profile />} />
                            )}
                          </Route>
                          <Route
                            path="/app/Devices"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_USER']}
                                pageId={PageIds.LINK_DEVICES}
                              />
                            }
                          >
                            <Route path="/app/Devices" element={<DevicesPage />} />
                          </Route>
                          <Route
                            path="/app/DeviceGroups"
                            element={<PrivateOutlet pageId={PageIds.LINK_DEVICE_GROUPS} />}
                          >
                            <Route path="/app/DeviceGroups" element={<DeviceGroups />} />
                          </Route>
                          <Route
                            path="/app/UtilityRates"
                            allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                            element={<PrivateOutlet pageId={PageIds.LINK_UTILITY_RATE} />}
                          >
                            <Route path="/app/UtilityRates" element={<UtilityRatePage />} />
                          </Route>
                          <Route
                            path="/app/Graph/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_NEW_GRAPH_DEVICE} />}
                          >
                            <Route path="/app/Graph/:deviceNumber" element={<Graph />} />
                          </Route>
                          <Route
                            path="/app/DeviceCounters/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_READINGS_DEVICE} />}
                          >
                            <Route
                              path="/app/DeviceCounters/:deviceNumber"
                              element={<CountersExport />}
                            />
                          </Route>
                          <Route
                            path="/app/Commands/:deviceNumber"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_COMMANDS_DEVICE}
                              />
                            }
                          >
                            <Route path="/app/Commands/:deviceNumber" element={<Commands />} />
                          </Route>
                          <Route
                            path="/app/CopyConfig/:deviceNumber"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_COPY_CONFIG_DEVICE}
                              />
                            }
                          >
                            <Route
                              path="/app/CopyConfig/:deviceNumber"
                              element={<CopyConfigPage />}
                            />
                          </Route>
                          <Route
                            path="/app/DeviceManager/"
                            element={<PrivateOutlet pageId={PageIds.LINK_DEVICE_MANAGER} />}
                          >
                            <Route path="/app/DeviceManager/" element={<DeviceManager />} />
                          </Route>
                          <Route
                            path="/app/LinkDevices/"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_LINK_DEVICE}
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                              />
                            }
                          >
                            {this.isFeatureFlagOn('LINK_DEVICE_LICENSE_CHECK') ? (
                              <Route path="/app/LinkDevices/" element={<LinkDevicesV2 />} />
                            ) : (
                              <Route path="/app/LinkDevices/" element={<LinkDevices />} />
                            )}
                          </Route>

                          <Route
                            path="/app/DeviceCompanyLookup"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_DEVICE_ACCOUNT_LOOKUP}
                              />
                            }
                          >
                            <Route path="/app/DeviceCompanyLookup" element={<DeviceSearch />} />
                          </Route>
                          <Route
                            path="/app/DeviceConfig/:deviceNumber"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_CONFIG_DEVICE}
                              />
                            }
                          >
                            <Route
                              path="/app/DeviceConfig/:deviceNumber"
                              element={<DeviceConfig />}
                            />
                          </Route>
                          <Route
                            path="/app/SensorsConfig/:deviceNumber"
                            allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                            element={<PrivateOutlet pageId={PageIds.LINK_SENSOR_CONFIG_DEVICE} />}
                          >
                            <Route
                              path="/app/SensorsConfig/:deviceNumber"
                              element={<SensorsConfig />}
                            />
                          </Route>
                          <Route
                            path="/app/DeviceInitialConfig/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_INITIAL_CONFIG_DEVICE} />}
                          >
                            <Route
                              path="/app/DeviceInitialConfig/:deviceNumber"
                              element={<DeviceInitialConfig />}
                            />
                          </Route>
                          <Route
                            path="/app/DeviceCountersStatistics/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_STATISTICS_DEVICE} />}
                          >
                            <Route
                              path="/app/DeviceCountersStatistics/:deviceNumber"
                              element={<Statistics />}
                            />
                          </Route>
                          <Route
                            path="/app/MultiDeviceCounterStatistics"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_MULTI_DEVICE_COUNTERS_STATISTICS}
                              />
                            }
                          >
                            <Route
                              path="/app/MultiDeviceCounterStatistics"
                              element={<MultiDeviceCounterStatistics />}
                            />
                          </Route>
                          <Route
                            path="/app/MultiDeviceCounterStatisticsFlow"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_MULTI_DEVICE_COUNTERS_STATISTICS_FLOW}
                              />
                            }
                          >
                            <Route
                              path="/app/MultiDeviceCounterStatisticsFlow"
                              element={<MultiDeviceCounterStatisticsFlow />}
                            />
                          </Route>
                          <Route
                            path="/app/MultiDeviceCounterStatisticsQFlow"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_MULTI_DEVICE_COUNTERS_STATISTICS_Q_FLOW}
                              />
                            }
                          >
                            <Route
                              path="/app/MultiDeviceCounterStatisticsQFlow"
                              element={<MultiDeviceCounterStatisticsQFlow />}
                            />
                          </Route>
                          <Route
                            path="/app/MultiDeviceCounterStatisticsByTime"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_MULTI_DEVICE_COUNTERS_STATISTICS_BY_TIME}
                              />
                            }
                          >
                            <Route
                              path="/app/MultiDeviceCounterStatisticsByTime"
                              element={<MultiDeviceCounterStatisticsByTime />}
                            />
                          </Route>
                          <Route
                            path="/app/MultiDeviceFlowsGraph"
                            element={
                              <PrivateOutlet pageId={PageIds.LINK_MULTI_DEVICE_FLOW_GRAPH} />
                            }
                          >
                            <Route
                              path="/app/MultiDeviceFlowsGraph"
                              element={<MultiDeviceFlowsGraph />}
                            />
                          </Route>
                          <Route
                            path="/app/CustomerInfo/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_CUSTOMER_DEVICE} />}
                          >
                            <Route
                              path="/app/CustomerInfo/:deviceNumber"
                              element={<CustomerInfo />}
                            />
                          </Route>
                          <Route
                            path="/app/MeterInfo/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_METER_INFO_DEVICE} />}
                          >
                            <Route path="/app/MeterInfo/:deviceNumber" element={<MeterInfo />} />
                          </Route>
                          <Route
                            path="/app/DeviceCountersExport/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_EXPORT_DEVICE} />}
                          >
                            <Route
                              path="/app/DeviceCountersExport/:deviceNumber"
                              element={<CountersExport />}
                            />
                          </Route>
                          <Route
                            path="/app/DeviceCountersExport"
                            element={<PrivateOutlet pageId={PageIds.LINK_EXPORT} />}
                          >
                            <Route path="/app/DeviceCountersExport" element={<CountersExport />} />
                          </Route>
                          <Route
                            path="/app/DeviceSignals/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_SIGNAL_DEVICE} />}
                          >
                            <Route
                              path="/app/DeviceSignals/:deviceNumber"
                              element={<DevicesSignalV2 />}
                            />
                          </Route>
                          <Route
                            path="/app/DeviceBattery/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_BATTERY_DEVICE} />}
                          >
                            <Route
                              path="/app/DeviceBattery/:deviceNumber"
                              element={<DeviceBatteryPageV2 />}
                            />
                          </Route>
                          <Route
                            path="/app/DeviceCountersGraph/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_FLOW_DEVICE} />}
                          >
                            <Route
                              path="/app/DeviceCountersGraph/:deviceNumber"
                              element={<DeviceCountersGraph />}
                            />
                          </Route>
                          <Route
                            path="/app/DeviceCountersCumulativeGraph/:deviceNumber"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_DEVICE_COUNTERS_CUMULATIVE_GRAPH}
                              />
                            }
                          >
                            <Route
                              path="/app/DeviceCountersCumulativeGraph/:deviceNumber"
                              element={<DeviceCountersCumulativeGraph />}
                            />
                          </Route>
                          <Route
                            path="/app/Transmissions"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_TRANSMISSIONS}
                              />
                            }
                          >
                            <Route path="/app/Transmissions" element={<TransmissionsPage />} />
                          </Route>
                          <Route
                            path="/app/DeviceInventory"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_INVENTORY}
                              />
                            }
                          >
                            <Route path="/app/DeviceInventory" element={<Inventory />} />
                          </Route>
                          <Route
                            path="/app/Alarms"
                            element={<PrivateOutlet pageId={PageIds.LINK_ALARMS_DEVICE} />}
                          >
                            <Route index element={<AlarmsExport />} />
                            <Route path=":deviceNumber" element={<AlarmsExport />} />
                          </Route>
                          <Route
                            path="/app/AllAlarms"
                            element={<PrivateOutlet pageId={PageIds.LINK_ALARMS} />}
                          >
                            <Route path="/app/AllAlarms" element={<AlarmsExport />} />
                            <Route path="/app/AllAlarms/:alarmType" element={<AlarmsExport />} />
                          </Route>
                          <Route
                            path="/app/AlarmConfig/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_ALARM_CONFIG_DEVICE} />}
                          >
                            <Route
                              path="/app/AlarmConfig/:deviceNumber"
                              element={<AlarmConfig />}
                            />
                          </Route>
                          <Route
                            path="/app/FlowGenerator/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_FLOW_GENERATOR_DEVICE} />}
                          >
                            <Route
                              path="/app/FlowGenerator/:deviceNumber"
                              element={<FlowGenerator />}
                            />
                          </Route>
                          <Route
                            path="/app/DeviceLocation"
                            element={<PrivateOutlet pageId={PageIds.LINK_MAP} />}
                          >
                            <Route index element={<Location />} />
                          </Route>
                          <Route
                            path="/app/DeviceLocation/:deviceNumber"
                            element={<PrivateOutlet pageId={PageIds.LINK_MAP_DEVICE} />}
                          >
                            <Route
                              path="/app/DeviceLocation/:deviceNumber"
                              element={<Location />}
                            />
                          </Route>
                          <Route
                            path="/app/DevicesBattery"
                            element={<PrivateOutlet pageId={PageIds.LINK_BATTERY} />}
                          >
                            <Route path="/app/DevicesBattery" element={<DeviceBatteryPageV2 />} />
                            <Route
                              path="/app/DevicesBattery/:fromLevel"
                              element={<DeviceBatteryPageV2 />}
                            />
                          </Route>
                          <Route
                            path="/app/DevicesSignal"
                            element={<PrivateOutlet pageId={PageIds.LINK_SIGNAL} />}
                          >
                            <Route path="/app/DevicesSignal" element={<DevicesSignalV2 />} />
                            <Route
                              path="/app/DevicesSignal/:fromLevel"
                              element={<DevicesSignalV2 />}
                            />
                          </Route>
                          <Route
                            path="/app/ChangePassword/"
                            element={<PrivateOutlet pageId={PageIds.LINK_CHANGE_PASSWORD} />}
                          >
                            <Route path="/app/ChangePassword/" element={<ChangePassword />} />
                          </Route>
                          <Route
                            path="/app/Users/"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_SHOW_USERS}
                              />
                            }
                          >
                            <Route path="/app/Users/" element={<Users />} />
                          </Route>
                          <Route
                            path="/app/Accounts/"
                            allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                            element={<PrivateOutlet pageId={PageIds.LINK_ACCOUNTS} />}
                          >
                            <Route path="/app/Accounts/" element={<AccountsPage />} />
                          </Route>
                          {!this.isFeatureFlagOn('CREATE_ACCOUNT_MODAL') && (
                            <Route
                              path="/app/AccountsManager/"
                              element={
                                <PrivateOutlet
                                  allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                  pageId={PageIds.LINK_ACCOUNTS_MANAGER}
                                />
                              }
                            >
                              {this.isFeatureFlagOn('LICENSE_MANAGEMENT_FEATURE_FLAG') ? (
                                <Route
                                  path="/app/AccountsManager/"
                                  element={<CompanyManagerV2 />}
                                />
                              ) : (
                                <Route path="/app/AccountsManager/" element={<CompanyManager />} />
                              )}
                            </Route>
                          )}
                          <Route
                            path="/app/Language/"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_LANGUAGE}
                                isNonRestrictedAccess={true}
                              />
                            }
                          >
                            <Route path="/app/Language/" element={<Language />} />
                          </Route>
                          <Route
                            path="/app/ReportsExport/"
                            element={<PrivateOutlet pageId={PageIds.LINK_REPORTS} />}
                          >
                            <Route path="/app/ReportsExport/" element={<ConsumptionExport />} />
                          </Route>
                          <Route
                            path="/app/LicenseManager/"
                            element={<PrivateOutlet pageId={PageIds.LINK_LICENSE_MANAGER} />}
                          >
                            <Route path="/app/LicenseManager/" element={<LicenseManager />} />
                          </Route>
                          <Route
                            path="/app/Licenses/"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_LICENSES}
                              />
                            }
                          >
                            {this.props.auth.hasLicensesAccess && (
                              <Route path="/app/Licenses/" element={<LicensePage />} />
                            )}
                          </Route>
                          <Route
                            path="/app/DevicePrediction/:deviceNumber"
                            element={
                              <PrivateOutlet pageId={PageIds.LINK_DEVICE_PREDICTION_DEVICE} />
                            }
                          >
                            <Route
                              path="/app/DevicePrediction/:deviceNumber"
                              element={<DevicePrediction />}
                            />
                          </Route>
                          <Route
                            path="/app/EventValueRange/:deviceNumber"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_VALUE_RANGES_DEVICE}
                              />
                            }
                          >
                            <Route
                              path="/app/EventValueRange/:deviceNumber"
                              element={<EventValueRangePage />}
                            />
                          </Route>
                          <Route path="/app/Settings/" element={<PrivateOutlet />}>
                            <Route path="/app/Settings/" element={<SettingsV2 />} />
                          </Route>
                          <Route path="/app/docs" element={<PrivateOutlet />}>
                            <Route path="/app/docs" element={<Documentation />} />
                          </Route>
                          <Route
                            path="/app/NewGraph"
                            element={<PrivateOutlet pageId={PageIds.LINK_NEW_GRAPH} />}
                          >
                            <Route path="/app/NewGraph" element={<GraphV2 />} />
                          </Route>
                          <Route
                            path="/app/BillingReport"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_BILLING_REPORT}
                              />
                            }
                          >
                            <Route path="/app/BillingReport" element={<BillingReport />} />
                          </Route>
                          <Route
                            path="/app/icons"
                            element={
                              <PrivateOutlet
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
                                pageId={PageIds.LINK_ICONS}
                              />
                            }
                          >
                            <Route path="/app/icons" element={<IconsPage />} />
                          </Route>
                          <Route
                            path="/app/icons/add"
                            element={<PrivateOutlet pageId={PageIds.LINK_ICONS} />}
                          >
                            <Route path="/app/icons/add" element={<AddIconPage />} />
                          </Route>
                          <Route
                            path="/app/CustomerBillingReport"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_CUSTOMER_BILLING_REPORT}
                                allowedRoles={['ROLE_SUPER_ADMIN', 'ROLE_BILLING']}
                              />
                            }
                          >
                            <Route
                              path="/app/CustomerBillingReport"
                              element={<CustomerBillingReport />}
                            />
                          </Route>
                          <Route
                            path="/app/ParametersConfiguration/:deviceNumber"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.LINK_SENSORS_AND_NETWORK_CONFIG}
                                allowedRoles={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']}
                              />
                            }
                          >
                            {this.isFeatureFlagOn('SENSORS_AND_NETWORK_CONFIG') && (
                              <Route
                                path="/app/ParametersConfiguration/:deviceNumber"
                                element={<SensorsAndNetworkConfig />}
                              />
                            )}
                          </Route>
                          <Route
                            path="/app/restricted-access"
                            element={
                              <PrivateOutlet
                                pageId={PageIds.RESTRICTED_ACCESS}
                                isNonRestrictedAccess={true}
                              />
                            }
                          >
                            <Route path="" element={<LicenseError />} />
                          </Route>
                          <Route
                            path="/app/DeviceNodes"
                            element={<PrivateOutlet pageId={PageIds.LINK_DEVICE_NODES} />}
                          >
                            <Route path="" element={<DeviceNodesPage />} />
                          </Route>
                        </Routes>
                      </div>
                    </LocalizationProvider>
                  </ToastProvider>
                </ModalProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </JssProvider>
        </ReactQueryWrapper>
      </GoogleReCaptchaProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    featureFlags: state.featureFlags,
  };
}

export default connect(mapStateToProps, actions)(withTranslation()(App));
