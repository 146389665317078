import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  deviceConfigQueryClient,
  deviceNodeLinksQueryClient,
  devicesQueryClient,
  initialConfigQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import JwtValidator from '../../common/JwtValidator';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import Header from '../../components/header';
import { useToast, useModal } from '@thingslog/ui-components';
import {
  Device,
  DeviceNodeLinkDto,
  Pagination,
  PortsConfig,
  CurrentDeviceRole,
} from '@thingslog/repositories';
import DeviceNodesTable from './DeviceNodesTable';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DeviceNodeLinkCreateModal from './DeviceNodeLinkCreateModal';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import { QueryKeys } from '@thingslog/queries/src/enums/QueryKeys';
import { useQueryClient } from '@tanstack/react-query';
import DeviceNodeLinkDeleteModal from './DeviceNodeLinkDeleteModal';

export const DeviceNodesPage: FC<DeviceNodesPageProps> = () => {
  const {
    useDeviceNodesData,
    useCreateDeviceNodeLink,
    useDeleteAllDeviceNodesForDevice,
    useDeleteDeviceNodesForDeviceSensor,
  } = deviceNodeLinksQueryClient;
  const { useDevicesData } = devicesQueryClient;
  const { useGetDevicesPortsConfigsBatch } = initialConfigQueryClient;

  const { modal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { hasRole } = useMemo(() => new JwtValidator(), []);

  const { decodedToken } = useMemo(() => new JwtValidator(), []);
  const selectedCompanyId = useSelector((state: ReduxState) => state.company.id);
  const { toast } = useToast();
  const [deviceNodes, setDeviceNodes] = useState<DeviceNodeLinkDto[]>([]);
  const { t } = useTranslation();

  const currCompanyId = useMemo(() => {
    try {
      if (selectedCompanyId) {
        return selectedCompanyId;
      }
      if (decodedToken) {
        return Number(decodedToken.companyId);
      }
      return null;
    } catch {
      return null;
    }
  }, [decodedToken, selectedCompanyId]);

  const devicesNodesQuery = useDeviceNodesData(currCompanyId ?? undefined, {
    enabled: currCompanyId !== null,
  });

  useEffect(() => {
    if (devicesNodesQuery.error) {
      toast({
        type: 'error',
        message: t('device_nodes_table_error'),
      });
    }
  }, [devicesNodesQuery.error]);

  useEffect(() => {
    if (devicesNodesQuery.data) {
      setDeviceNodes(devicesNodesQuery.data);
    }
  }, [devicesNodesQuery.data]);

  const devicesQuery = useDevicesData(currCompanyId!, 0, 9999, null, null, {
    enabled: currCompanyId !== null,
    onSuccess: (data: Pagination<Device>) => {
      deviceInitConfigMutation.mutate(data.content.map((device: Device) => device.number));
    },
  });

  const deviceInitConfigMutation = useGetDevicesPortsConfigsBatch({
    onSuccess: (data: Record<string, PortsConfig>) => {},
  });

  const deleteDeviceNodesForDeviceSensorMutation = useDeleteDeviceNodesForDeviceSensor({
    onSuccess: () => {
      toast({
        type: 'success',
        message: t('successfully_saved') || 'Link created successfully',
      });
      closeModal();
      queryClient.invalidateQueries([QueryKeys.UseDeviceNodeLinks]);
    },
  });

  const deleteAllDeviceNodesForDeviceMutation = useDeleteAllDeviceNodesForDevice({
    onSuccess: () => {
      toast({
        type: 'success',
        message: t('successfully_saved') || 'Link created successfully',
      });
      closeModal();
      queryClient.invalidateQueries([QueryKeys.UseDeviceNodeLinks]);
    },
  });

  const createDeviceNodeLinkMutation = useCreateDeviceNodeLink({
    onSuccess: () => {
      toast({
        type: 'success',
        message: t('successfully_saved') || 'Link created successfully',
      });
      closeModal();
      queryClient.invalidateQueries([QueryKeys.UseDeviceNodeLinks]);
    },
  });

  const handleOpenCreateLinkModal = (): void => {
    modal({
      title: t('device_nodes_create_link') || 'Create Device Node Link',
      content: (
        <div className="md:w-[400px] overflow-y-auto max-h-[80vh]">
          <DeviceNodeLinkCreateModal
            devices={devicesQuery.data?.content || []}
            onCreateLink={(linkData: DeviceNodeLinkDto): void => {
              createDeviceNodeLinkMutation.mutate(linkData);
            }}
            portsConfigs={deviceInitConfigMutation.data ?? null}
          />
        </div>
      ),
    });
  };

  const handleDeleteLinks = (): void => {
    modal({
      title: t('device_nodes_delete_link') || 'Delete Device Node Links',
      content: (
        <div className="md:w-[400px] overflow-y-auto max-h-[80vh]">
          <DeviceNodeLinkDeleteModal
            devices={devicesQuery.data?.content || []}
            onDeleteAllDeviceNodes={(deviceNumber: string, role: CurrentDeviceRole): void => {
              deleteAllDeviceNodesForDeviceMutation.mutate({
                deviceNumber,
                role,
              });
            }}
            onDeleteDeviceSensorNodes={(
              deviceNumber: string,
              sensorIndex: number,
              role: CurrentDeviceRole
            ): void => {
              deleteDeviceNodesForDeviceSensorMutation.mutate({
                deviceNumber,
                sensorIndex,
                role,
              });
            }}
            portsConfigs={deviceInitConfigMutation.data ?? null}
          />
        </div>
      ),
    });
  };

  const handleDeleteSelectedLinks = (): void => {
    handleDeleteLinks();
  };

  return (
    <Header>
      {(hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && (
        <section className="flex justify-end flex-wrap mb-5">
          <div className="flex space-x-2 max-lg:mt-4 max-md:w-full">
            <Button
              variant="contained"
              color="error"
              className="max-md:w-full max-md:mt-4"
              onClick={handleDeleteSelectedLinks}
            >
              {t('device_nodes_delete_links') || 'Delete Links'}
            </Button>
            <Button
              variant="contained"
              className="max-md:w-full max-md:mt-4"
              onClick={handleOpenCreateLinkModal}
            >
              {t('device_nodes_create_link') || 'Create Link'}
            </Button>
          </div>
        </section>
      )}

      <DeviceNodesTable
        deviceNodeLinks={deviceNodes}
        loading={devicesNodesQuery.isFetching}
        autoHeight={true}
        disableSelectionOnClick={true}
        disableColumnMenu={true}
        hideFooterSelectedRowCount
        components={{
          NoRowsOverlay: () => (
            <div className="h-full flex items-center justify-center">
              {t('device_nodes_table_no_links_found') || 'No device node links found'}
            </div>
          ),
        }}
      />
    </Header>
  );
};

interface DeviceNodesPageProps {}
