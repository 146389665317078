import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmDeleteOrUnlinkModal: FC<ConfirmDeleteOrUnlinkModal> = ({
  onUnlinkDevice,
  onDeleteDevice,
  showUnlinkBtn,
}: ConfirmDeleteOrUnlinkModal) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-2">
        {showUnlinkBtn
          ? t('device_inventory_delete_unlink_modal_body')
          : t('device_inventory_delete_modal_body')}
      </div>

      <div className="flex gap-2 w-full justify-center">
        <Button
          className="flex-grow"
          color="error"
          variant="contained"
          disableElevation
          onClick={(): void => {
            onDeleteDevice();
          }}
        >
          {t('delete')}
        </Button>
        {showUnlinkBtn && onUnlinkDevice && (
          <Button
            className="flex-grow"
            color="primary"
            variant="contained"
            disableElevation
            onClick={(): void => {
              onUnlinkDevice();
            }}
          >
            {t('unlink')}
          </Button>
        )}
      </div>
    </>
  );
};

interface ConfirmDeleteOrUnlinkModal {
  onDeleteDevice: () => void;
  onUnlinkDevice?: () => void;
  showUnlinkBtn: boolean;
}

export default ConfirmDeleteOrUnlinkModal;
