import React, { Component } from 'react';
import * as actions from './actions';
import { connect } from 'react-redux';
import { BarChart, PieChart, Legend } from 'react-easy-chart';
import { Grid, Row, Col } from 'react-flexbox-grid';
import alarmsClient from './AlarmsClient';
import deviceBatteryClient from './DeviceBatteryClient';
import deviceSignalsClient from './DeviceSignalsClient';
import Header from './components/header';
import { withRouter } from './common/withRouter';
import * as roles from './common/role_utils';
import { withTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import JwtValidator from './common/JwtValidator';
import ErrorUtil from './common/ErrorUtil';

const ALARM_TYPES = {
  NO_ZERO_CONSUMPTION: 'alarm_type_no_zero_consumption',
  HIGH_CONSUMPTION: 'alarm_type_high_consumption',
  HIGH_FLOW: 'alarm_type_high_flow',
  MISSED_TRANSMISSION: 'alarm_type_missed_transmission',
  LOW_FLOW: 'alarm_type_low_flow',
  ALL_ZERO_CONSUMPTION: 'alarm_type_all_zero_consumption',
  DEVICE_REMOVED: 'alarm_type_device_removed',
  ON_OFF_ALARM: 'alarm_type_on_off_alarm',
  LOW_LEVEL: 'alarm_type_low_level',
  HIGH_LEVEL: 'alarm_type_high_level',
  TAMPER: 'alarm_type_tamper',
  OVERSIZED: 'alarm_type_oversized',
  UNDERSIZED: 'alarm_type_undersized',
  LOW_BATTERY: 'alarm_type_low_battery',
  BLOCKAGE: 'alarm_type_blockage',
  BURST: 'alarm_type_burst',
  REVERSE_FLOW: 'alarm_type_reserve_flow',
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alarms: null,
      batteries: null,
      signals: null,
      certFileExists: true,
      certPath: undefined,
      certPathSettingsExist: false,
    };
    this.loadAlarmsFromServer = this.loadAlarmsFromServer.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.pieClicked = this.pieClicked.bind(this);
    this.batteriesClicked = this.batteriesClicked.bind(this);
    this.signalsClicked = this.signalsClicked.bind(this);
    this.getCompanyId = this.getCompanyId.bind(this);
    this.getCertificateUrl = this.getCertificateUrl.bind(this);
    this.checkFileExists = this.checkFileExists.bind(this);
    this.jwtValidator = new JwtValidator();
  }

  componentDidMount() {
    let euroCertPath = this.props.settings?.data?.certificateEuroPath;
    this.setState({
      certPath: euroCertPath,
      certPathSettingsExist: !!euroCertPath,
    });
    let hasSuperAdminRole = roles.hasRole(this.props.auth, roles.ROLE_SUPER_ADMIN);
    let hasAdminRole = roles.hasRole(this.props.auth, roles.ROLE_ADMIN);
    let hasUserRole = roles.hasRole(this.props.auth, roles.ROLE_USER);
    // Block superadmin from making requests if no company is selected.
    // Allow users with admin or user roles to make requests even if no company is selected.
    if (
      (!hasSuperAdminRole && (hasAdminRole || hasUserRole)) ||
      (hasSuperAdminRole && this.props.company.id !== null)
    ) {
      this.loadAlarmsFromServer();
    }
  }

  componentDidUpdate(prevProps) {
    let currEuroCertPath = this.props.settings?.data?.certificateEuroPath;
    let prevEuroCertPath = prevProps.settings?.data?.certificateEuroPath;
    let currCompanyId = this.props.company.id;
    let prevCompanyId = prevProps.company.id;

    if (currEuroCertPath !== prevEuroCertPath) {
      this.setState({
        certPath: currEuroCertPath,
        certPathSettingsExist: !!currEuroCertPath,
      });
      if (currEuroCertPath) {
        this.checkFileExists(currEuroCertPath);
      }
    }
    if (currCompanyId !== prevCompanyId) {
      this.loadAlarmsFromServer();
    }
  }

  errorCallback(error) {
    ErrorUtil.handleErrorWithAlert(error);
  }

  batteriesClicked(d) {
    this.props.history.push('/app/DevicesBattery/' + d.key);
  }

  signalsClicked(d) {
    this.props.history.push('/app/DevicesSignal/' + d.key);
  }

  pieClicked(d) {
    let fromDate = new Date(new Date() - 24 * 60 * 60 * 1000);
    let toDate = new Date();
    this.props.periodFromDateChanged(fromDate);
    this.props.periodToDateChanged(toDate);
    this.props.history.push('/app/AllAlarms/' + d.data.key);
  }

  getCompanyId() {
    this.jwtValidator.determineCompanyIdForUserRole(this.props.company.id);
  }

  getCertificateUrl(imagePath) {
    return `${window.location.protocol}//${window.location.host}${imagePath}`;
  }

  checkFileExists = async (imagePath) => {
    try {
      const response = await fetch(this.getCertificateUrl(imagePath));
      this.setState({ certFileExists: response.ok });
    } catch (error) {
      this.setState({ certFileExists: false });
    }
  };

  render() {
    if (roles.hasRole(this.props.auth, roles.ROLE_BILLING)) {
      return <Navigate to="/app/CustomerBillingReport" />;
    }
    if (
      !roles.hasRole(this.props.auth, roles.ROLE_ADMIN) &&
      !roles.hasRole(this.props.auth, roles.ROLE_SUPER_ADMIN)
    ) {
      return <Navigate to="/app/Devices" />;
    }

    if (this.state.certFileExists && this.state.certPathSettingsExist && this.state.certPath) {
      return (
        <Header>
          <div>
            <Grid>
              <div className="flex items-center justify-center h-full min-h-screen">
                <iframe
                  className="w-full max-w-[780px] h-[500px] object-contain mx-auto border-none"
                  src={this.getCertificateUrl(this.state.certPath)}
                  title="Certificate Frame"
                />
              </div>
            </Grid>
          </div>
        </Header>
      );
    }

    let alarms = <div></div>;
    let batteries = <div></div>;
    let signals = <div></div>;
    if (this.state.alarms != null) {
      const alphabeticalAlarms = this.state.alarms.sort((a, b) => {
        if (a.key === b.key) return 0;
        return a.key < b.key ? -1 : 1;
      });

      alarms = (
        <div>
          <p>{this.props.t('dashboard_alarms')}</p>
          <PieChart size={250} data={alphabeticalAlarms} clickHandler={this.pieClicked} />
          <Legend data={alphabeticalAlarms} dataId={'label'} />
        </div>
      );
    }
    if (this.state.batteries != null) {
      batteries = (
        <div>
          <p>{this.props.t('dashboard_batteries')}</p>
          <BarChart
            margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
            height={250}
            width={350}
            axes
            grid
            axisLabels={{
              x: this.props.t('dashboard_chart_battery_level'),
              y: this.props.t('dashboard_chart_number_of_devices'),
            }}
            data={this.state.batteries}
            clickHandler={this.batteriesClicked}
          />
        </div>
      );
    }

    if (this.state.signals != null) {
      signals = (
        <div>
          <p>{this.props.t('dashboard_signal')}</p>
          <BarChart
            margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
            height={250}
            width={350}
            axes
            grid
            axisLabels={{
              x: this.props.t('dashboard_chart_signal_level'),
              y: this.props.t('dashboard_chart_number_of_devices'),
            }}
            data={this.state.signals}
            clickHandler={this.signalsClicked}
          />
        </div>
      );
    }

    return (
      <Header>
        {roles.hasRole(this.props.auth, roles.ROLE_SUPER_ADMIN) && !this.props.company.id ? (
          <div className="mt-72">
            <Typography variant="h4">{this.props.t('dashboard_select_company_header')}</Typography>
            <Typography variant="h5">
              {this.props.t('dashboard_select_company_from')}
              <Link to={`/app/Accounts`}>
                {this.props.t('dashboard_select_company_accounts_page_link')}
              </Link>
            </Typography>
          </div>
        ) : (
          <div>
            <Grid>
              <Row>
                <Col sm={5} md={5} lg={5}>
                  {alarms}
                </Col>
                <Col sm={7} md={7} lg={7}>
                  {batteries}
                  {signals}
                </Col>
              </Row>
            </Grid>
          </div>
        )}
      </Header>
    );
  }

  loadAlarmsFromServer() {
    let fromDate = new Date(new Date() - 24 * 60 * 60 * 1000);
    let toDate = new Date();
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    let fromDateISO = new Date(fromDate - tzoffset).toISOString();
    let toDateISO = new Date(toDate - tzoffset).toISOString();
    const companyId = this.props.company.id;

    alarmsClient.getAlarmsCount(
      companyId,
      fromDateISO,
      toDateISO,
      (data) => {
        let alarmPie = [];
        for (let i = 0; i < data.length; i++) {
          alarmPie.push({
            key: data[i].alarmType,
            value: data[i].count,
            label: this.props.t('dashboard_transmission', {
              alarm_type: this.props.t(ALARM_TYPES[data[i].alarmType]),
              alarm_count: data[i].count,
            }),
          });
        }

        this.setState({
          alarms: alarmPie,
        });
      },
      this.errorCallback
    );
    deviceBatteryClient.getDevicesBatteryHistogram(
      companyId,
      (data) => {
        let batteries = [];
        for (let i = 0; i < data.nbins; i++) {
          batteries.push({
            key: data.edges[i],
            x: '' + data.edges[i] + ' %',
            y: data.values[i],
          });
        }
        this.setState({
          batteries: batteries,
        });
      },
      this.errorCallback
    );
    deviceSignalsClient.getDevicesSignalHistogram(
      companyId,
      (data) => {
        let signals = [];
        for (let i = 0; i < data.nbins; i++) {
          signals.push({
            key: data.edges[i],
            x: '' + data.edges[i] + ' %',
            y: data.values[i],
          });
        }
        this.setState({
          signals: signals,
        });
      },
      this.errorCallback
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.company,
    devices: state.dev.devices,
    settings: state.settings,
  };
}

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(Dashboard)));
