import React, { FC, ReactNode, useMemo, Fragment, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { formatDuration, intervalToDuration } from 'date-fns';
import { Link } from 'react-router-dom';
import { Select, SelectChangeEvent, MenuItem } from '@mui/material';

import i18n from '../../../i18n';
import { hash } from '@thingslog/ui-components';
import {
  Device,
  DeviceCreateUpdateBody,
  DeviceIconDto,
  DeviceInitialConfig,
  RecordPeriod,
} from '@thingslog/repositories';

const DeviceDetails: FC<DeviceDetailsProps> = ({
  device,
  deviceInitialConfig,
  icons,
  onDeviceUpdate,
}: DeviceDetailsProps) => {
  const [deviceIconId, setDeviceIconId] = useState(device.deviceIconDto.id);
  const { t } = useTranslation();

  const onIconChange = (event: SelectChangeEvent): void => {
    let deviceIconId: number;
    try {
      deviceIconId = Number(event.target.value);
    } catch (e) {
      console.log('An error occurred while handling the icon change:', e);
      return;
    }
    setDeviceIconId(deviceIconId);
    onDeviceUpdate(device.number, {
      ...device,
      iconId: deviceIconId,
    });
  };

  const getDeviceIcon = (deviceIconDto: DeviceIconDto): ReactNode => {
    if (!deviceIconDto || !deviceIconDto.icon) {
      return <span></span>;
    }
    return (
      <Select
        value={deviceIconId.toString()}
        variant="standard"
        disableUnderline
        size="small"
        onChange={onIconChange}
      >
        {icons &&
          icons.map((icon: DeviceIconDto) => (
            <MenuItem key={hash(icon.name)} value={icon.id}>
              <img
                src={icon.icon}
                alt={icon.name}
                style={{ height: '2rem', width: 'auto', marginInlineEnd: '0.5rem' }}
              />
              <span>{icon.name}</span>
            </MenuItem>
          ))}
      </Select>
    );
  };

  const deviceDetailsToDisplay: DeviceDetail[] = useMemo(() => {
    const convertRecordPeriodToSeconds = (time: number, recordPeriod: RecordPeriod): number => {
      let durationInSeconds: number | null;
      switch (recordPeriod) {
        case RecordPeriod.SECONDS:
          durationInSeconds = time;
          break;
        case RecordPeriod.MINUTES:
          durationInSeconds = time * 60;
          break;
        case RecordPeriod.HOURS:
          durationInSeconds = time * 60 * 60;
          break;
        case RecordPeriod.DAYS:
          durationInSeconds = time * 24 * 60 * 60;
          break;
        default:
          durationInSeconds = NaN;
          break;
      }
      return durationInSeconds;
    };

    const details: DeviceDetail[] = [
      {
        type: 'text',
        label: t('device_name'),
        value: device.name,
      },
      {
        type: 'text',
        label: t('device_number'),
        value: device.number,
      },
      {
        type: 'text',
        label: t('model'),
        value: device.model,
      },
      {
        type: 'text',
        label: t('nomenclature'),
        value: device.nomenclature || '',
      },
      {
        type: 'icon',
        label: t('device_icon'),
        iconDescription: device.deviceIconDto?.name || '',
        icon: getDeviceIcon(device.deviceIconDto),
      },
      {
        type: 'text',
        label: t('hardware_version'),
        value: device.hwVersion,
      },
      {
        type: 'text',
        label: t('software_version'),
        value: device.swVersion,
      },
      {
        type: 'text',
        label: t('description'),
        value: device.description || '',
      },
      {
        type: 'period',
        label: t('transmission_period'),
        periodInSeconds: convertRecordPeriodToSeconds(
          deviceInitialConfig.transmissionSettings.countsThreshold *
            deviceInitialConfig.transmissionSettings.every,
          deviceInitialConfig.transmissionSettings.recordPeriod
        ),
      },
      {
        type: 'period',
        label: t('record_period'),
        periodInSeconds: convertRecordPeriodToSeconds(
          deviceInitialConfig.transmissionSettings.every,
          deviceInitialConfig.transmissionSettings.recordPeriod
        ),
      },
    ];

    return details;
  }, [device, deviceInitialConfig, i18n.language, deviceIconId]);

  const linksToDisplay = useMemo(
    (): LinksToDisplay[] => [
      { linkText: t('link_readings'), linkUrl: `/app/DeviceCounters/${device.number}` },
      { linkText: t('link_graph'), linkUrl: `/app/Graph/${device.number}` },
      { linkText: t('link_flow'), linkUrl: `/app/DeviceCountersGraph/${device.number}` },
      { linkText: t('link_transmissions'), linkUrl: '/app/Transmissions' },
      { linkText: t('link_map'), linkUrl: `/app/DeviceLocation/${device.number}` },
    ],
    [i18n.language, device.number]
  );

  return (
    <div>
      <div className="flex-grow grid auto-rows-min grid-cols-[auto_1fr] gap-y-5 gap-x-10 text-left">
        {deviceDetailsToDisplay.map((deviceDetail: DeviceDetail) => {
          switch (deviceDetail.type) {
            case 'text':
              return (
                <Fragment key={deviceDetail.label}>
                  <div className="font-bold">{deviceDetail.label}</div>
                  <div className="break-words overflow-hidden">{deviceDetail.value}</div>
                </Fragment>
              );
            case 'period':
              return (
                <Fragment key={deviceDetail.label}>
                  <div className="font-bold">{deviceDetail.label}</div>
                  <div>
                    {deviceDetail.periodInSeconds
                      ? formatDuration(
                          intervalToDuration({ start: 0, end: deviceDetail.periodInSeconds * 1000 })
                        )
                      : ''}
                  </div>
                </Fragment>
              );
            case 'icon':
              return (
                <Fragment key={deviceDetail.label}>
                  <div className="font-bold">{deviceDetail.label}</div>
                  <div className="flex items-center space-x-2">{deviceDetail.icon}</div>
                </Fragment>
              );
          }
        })}
      </div>
      <div className="grid grid-cols-3 gap-4 mt-4 text-left">
        {linksToDisplay.map(({ linkText, linkUrl }: { linkText: string; linkUrl: string }) => (
          <Link to={linkUrl} className="text-blue-500 font-bold underline" key={linkText}>
            {linkText}
          </Link>
        ))}
      </div>
    </div>
  );
};

interface DeviceDetailsProps {
  device: Device;
  deviceInitialConfig: DeviceInitialConfig;
  icons: DeviceIconDto[];
  onDeviceUpdate: (deviceNumber: string, body: DeviceCreateUpdateBody) => void;
}

interface LinksToDisplay {
  linkText: string;
  linkUrl: string;
}

interface DeviceDetailBase {
  type: 'text' | 'period' | 'icon';
  label: string;
}

interface TextDetail extends DeviceDetailBase {
  type: 'text';
  value: string;
}

interface DurationDetail extends DeviceDetailBase {
  type: 'period';
  periodInSeconds: number;
}

interface IconDetail extends DeviceDetailBase {
  type: 'icon';
  iconDescription: string;
  icon: ReactNode;
}

type DeviceDetail = TextDetail | DurationDetail | IconDetail;

export default DeviceDetails;
