import React, { FC } from 'react';
import {
  useForm,
  Controller,
  SubmitHandler,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
} from 'react-hook-form';
import { Device, CurrentDeviceRole, PortsConfig, Port } from '@thingslog/repositories';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

interface DeviceOption {
  label: string;
  value: string;
}

interface SensorOption {
  label: string;
  value: number;
}

interface DeviceNodeLinkDeleteFormData {
  deviceNumber: string;
  role: CurrentDeviceRole;
  sensorIndex?: number | null;
}

interface DeviceNodeLinkDeleteModalProps {
  devices: Device[];
  onDeleteAllDeviceNodes: (deviceNumber: string, role: CurrentDeviceRole) => void;
  onDeleteDeviceSensorNodes: (
    deviceNumber: string,
    sensorIndex: number,
    role: CurrentDeviceRole
  ) => void;
  portsConfigs: Record<string, PortsConfig> | null;
}

const DeviceNodeLinkDeleteModal: FC<DeviceNodeLinkDeleteModalProps> = ({
  devices,
  onDeleteAllDeviceNodes,
  onDeleteDeviceSensorNodes,
  portsConfigs,
}: DeviceNodeLinkDeleteModalProps) => {
  const { t } = useTranslation();

  // Create device options for dropdowns
  const deviceOptions: DeviceOption[] = React.useMemo(() => {
    return devices.map((device: Device) => ({
      label: `${device.name} (${device.number})`,
      value: device.number,
    }));
  }, [devices]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<DeviceNodeLinkDeleteFormData>({
    defaultValues: {
      deviceNumber: '',
      role: 'SOURCE',
      sensorIndex: null,
    },
    mode: 'onChange',
  });

  const watchDevice = watch('deviceNumber');
  const deviceConfig = portsConfigs?.[watchDevice] ?? null;

  const getSensorOptionsFromConfig = (config: PortsConfig | null): SensorOption[] => {
    if (!config) return [];

    return Object.entries(config)
      .filter(([_, portConfig]: [string, Port]) => portConfig.enabled !== false) // Only show enabled ports
      .map(([portIndex, portConfig]: [string, Port]) => ({
        label: `${portConfig.sensor.name} (${portIndex})`,
        value: parseInt(portIndex, 10),
      }));
  };

  const sensorOptions = React.useMemo(
    () => getSensorOptionsFromConfig(deviceConfig),
    [deviceConfig]
  );

  const handleFormSubmit: SubmitHandler<DeviceNodeLinkDeleteFormData> = (
    data: DeviceNodeLinkDeleteFormData
  ) => {
    if (data.sensorIndex !== null && data.sensorIndex !== undefined) {
      // Delete links for specific sensor
      onDeleteDeviceSensorNodes(data.deviceNumber, data.sensorIndex, data.role);
    } else {
      // Delete all links for device
      onDeleteAllDeviceNodes(data.deviceNumber, data.role);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col mt-5 gap-5">
      <Controller
        name="deviceNumber"
        control={control}
        rules={{
          required: (t('device_nodes_device_required') as string) || 'Device is required',
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceNodeLinkDeleteFormData, 'deviceNumber'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceNodeLinkDeleteFormData>;
        }): React.ReactElement => (
          <Autocomplete
            options={deviceOptions}
            value={
              deviceOptions.find((option: DeviceOption) => option.value === field.value) || null
            }
            onChange={(_: unknown, option: DeviceOption | null): void => {
              field.onChange(option ? option.value : '');
            }}
            renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
              <TextField
                {...params}
                size="small"
                label={t('device_nodes_device') || 'Device'}
                error={!!errors.deviceNumber}
                helperText={errors.deviceNumber?.message}
                required
              />
            )}
          />
        )}
      />

      <Controller
        name="role"
        control={control}
        rules={{
          required: (t('device_nodes_role_required') as string) || 'Role is required',
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceNodeLinkDeleteFormData, 'role'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceNodeLinkDeleteFormData>;
        }): React.ReactElement => (
          <FormControl size="small" required error={!!errors.role}>
            <InputLabel id="role-select-label">{t('device_nodes_role') || 'Role'}</InputLabel>
            <Select
              labelId="role-select-label"
              value={field.value}
              onChange={field.onChange}
              label={t('device_nodes_role') || 'Role'}
            >
              <MenuItem value="SOURCE">{t('device_nodes_role_source') || 'Source'}</MenuItem>
              <MenuItem value="TARGET">{t('device_nodes_role_target') || 'Target'}</MenuItem>
            </Select>
            {errors.role && <FormHelperText>{errors.role.message}</FormHelperText>}
          </FormControl>
        )}
      />

      <Controller
        name="sensorIndex"
        control={control}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceNodeLinkDeleteFormData, 'sensorIndex'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceNodeLinkDeleteFormData>;
        }): React.ReactElement => (
          <FormControl fullWidth size="small" disabled={!watchDevice || !sensorOptions.length}>
            <Autocomplete
              options={sensorOptions}
              value={
                field.value !== null && field.value !== undefined
                  ? sensorOptions.find((option: SensorOption) => option.value === field.value) ||
                    null
                  : null
              }
              onChange={(_: unknown, option: SensorOption | null): void => {
                field.onChange(option ? option.value : null);
              }}
              renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
                <TextField
                  {...params}
                  size="small"
                  label={t('device_nodes_sensor_index') || 'Sensor (Optional)'}
                  helperText={
                    !sensorOptions.length && watchDevice
                      ? t('device_nodes_no_sensors_available') || 'No sensors available'
                      : t('device_nodes_sensor_optional') ||
                        'Leave empty to delete all device links'
                  }
                />
              )}
            />
          </FormControl>
        )}
      />

      <div className="text-sm mt-2">
        {t('device_nodes_delete_warning') || 'Warning: This action cannot be undone!'}
      </div>

      <Button type="submit" disabled={!isValid} variant="contained" color="error">
        {t('device_nodes_delete_button') || 'Delete Links'}
      </Button>
    </form>
  );
};

export default DeviceNodeLinkDeleteModal;
